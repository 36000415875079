import React, { ReactElement, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  FormControl,
  FormControlLabel,
  Checkbox,
  LinearProgress,
  useMediaQuery,
  useTheme,
  Tooltip,
  Chip,
  Divider,
} from "@material-ui/core";
import {
  Refresh as RefreshIcon,
} from "@material-ui/icons";
import { ReporteColumn, ReporteItem, ReporteModel, ReporteRow } from "models/reporte_model";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { parse as dateParse } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { I18n, Translate } from "react-redux-i18n";
import moment from 'moment';
import { getReporte } from "repositories/reporte_repository";
import { useIsMounted } from "hooks/useIsMounted";
import { STORAGE_URL } from "config/app.config";
import clsx from "clsx";
import { Alert, AlertTitle } from "@material-ui/lab";

const FORMAT = "dd/MM/yyyy";
const DEFAULT_ROWS_PER_PAGE = 50;
const DEFAULT_DESDE = moment().format('DD/MM/YYYY');
const DEFAULT_HASTA = moment().format('DD/MM/YYYY');

type Props = {
  estudiante?: string | null;
};

const AsistenciasReporteSimple = ({ estudiante }: Props): ReactElement => {
  const tipoReporte = 'KARDEX_ASISTENCIAS';
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [checkVerAsistencias, setCheckVerAsistencias] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const isMounted = useIsMounted();

  const [stateFilters, setStateFilters] = React.useState<any>({});

  const [desde, setDesde] = React.useState<string | null>(DEFAULT_DESDE);
  const [hasta, setHasta] = React.useState<string | null>(DEFAULT_HASTA);

  const [rows, setRows] = React.useState<ReporteRow[]>([]);
  const [columns, setColumns] = React.useState<ReporteColumn[]>([]);
  const [count, setCount] = React.useState<number>(0);

  const cargarReporte = async (filters: any, page: number, rowsPerPage: number) => {
    setLoading(true);
    filters.estudiante = estudiante;
    filters.check = checkVerAsistencias;
    const query = { _limit: rowsPerPage, _page: page + 1 }

    let desdeTmp = moment(desde, 'DD/MM/YYYY');
    let hastaTmp = moment(hasta, 'DD/MM/YYYY');

    if(desdeTmp.isValid() && hastaTmp.isValid()) {
      
      if(desdeTmp.year() < 2020 || hastaTmp.year() <2020)
        return;

      const fechaDesde = desdeTmp.format('YYYY-MM-DD');
      const fechaHasta = hastaTmp.format('YYYY-MM-DD');
      const result = await getReporte(tipoReporte, fechaDesde, fechaHasta, filters, query) as ReporteModel;
      if (isMounted()) setLoading(false);
      if (result) {
        setRows(result.rows);
        setColumns(result.columns);
        setCount(result.count);
      }
    }
  };

  const cargadoInicial = async () => {
    setStateFilters({});
    setPage(0);
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
    cargarReporte({}, 0, DEFAULT_ROWS_PER_PAGE);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cargadoInicialCallback = React.useCallback(cargadoInicial, [estudiante, desde, hasta, checkVerAsistencias]);

  useEffect(() => {
    cargadoInicialCallback();
  }, [cargadoInicialCallback, estudiante, desde, hasta, checkVerAsistencias]);

  const handleRefreshReporte = () => {
    setPage(0);
    cargarReporte(stateFilters, 0, rowsPerPage);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    cargarReporte(stateFilters, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    cargarReporte(stateFilters, 0, newRowsPerPage);
  };

  const handleChangeVerAsistencias = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCheckVerAsistencias(checked);
  };

  const textoTraducido: any = {
    'Asistió': I18n.t('tabla.asistio'),
    'Atrasado': I18n.t('tabla.atrasado'),
    'Falta con licencia': I18n.t('tabla.faltaConLicencia'),
    'Falta sin licencia': I18n.t('tabla.faltaSinLicencia'),
    'Permiso parcial': I18n.t('tienePermisoParcial'),
  }

  const renderText = (value: ReporteItem): ReactElement => {
    if (typeof value !== 'string') return renderValorComplejo(value);
    return (
      <>
        {value.split('|').map((line, lineIndex) => {
          const textLabel = textoTraducido[line] || line;
          return <Typography key={lineIndex}>{textLabel}</Typography>;
        })}
      </>
    );
  };

  const handleClickArchivoAdjunto = (fileName: string) => {
    const FILE_URL = `${STORAGE_URL}/${fileName}`;
    window.open(FILE_URL);
  };

  const truncateText = (text: string, max: number = 20) => {
    if (text.length <= max) return text;
    const middle = parseInt(`${max / 2}`);
    const a = text.substr(0, middle);
    const b = text.substr(text.length - middle);
    const truncated = `${a} ... ${b}`;
    return truncated;
  };

  const renderValorComplejo = (originalValue: ReporteItem): ReactElement => {
    if (typeof originalValue === "string" || originalValue === undefined || originalValue === null) return <></>;
    
    if(originalValue?.id === "adjuntos") {
      if((originalValue.value ?? "").trim() === "") return <></>;
      return renderAdjuntos(originalValue.value)

    }
    
    
    if (!originalValue.data || !originalValue.data.permisos || originalValue.data.permisos.length === 0) return <></>;
    return renderPermisos(originalValue.data.permisos);
  };

  const renderPermisos = (permisos: any[]) => {
    return (
      <>
        {permisos.map((item: any, index: number) => (
          <Box key={index}>
            {item.esParcial && (
              <Box>
                <Chip
                  className={clsx(classes.chip)}
                  label={I18n.t('tienePermisoParcial')}
                  color="primary"
                  size="small"
                />
              </Box>
            )}
            <Box>
              <strong>
                <Translate value={`opcion.MOTIVO_${item.motivo}`} />
              </strong>
            </Box>
            <Box>{item.descripcion}</Box>
            <Box>
              <strong><Translate value="tabla.desde" />: </strong> {item.fechaInicio}
            </Box>
            <Box>
              <strong><Translate value="tabla.hasta" />: </strong> {item.fechaFin}
            </Box>
            {renderAdjuntos(item.adjuntos)}
            {item.altaMedica && (
              <Alert severity="success">
                <AlertTitle>
                  <strong>
                    <Translate value="altaMedica" />
                  </strong>
                </AlertTitle>
                <table className={classes.tablaDetalle}>
                  <tbody>
                    <tr>
                      <th style={{ width: "50px" }}>
                        <Translate value="form.fecha" />:
                      </th>
                      <td>{item.altaMedica.fecha}</td>
                    </tr>
                  </tbody>
                </table>
                {renderAdjuntos(item.altaMedica.adjuntos)}
              </Alert>
            )}
            {index < permisos.length - 1 && <Box py={1}><Divider /></Box>}
          </Box>
        ))}
      </>
    );
  }

  const renderAdjuntos = (adjuntos: any[] | string) => {
    adjuntos = adjuntos && Array.isArray(adjuntos) ? adjuntos : typeof adjuntos === 'string' ? String(adjuntos).split(',') : [];
    return (
      <>
        {adjuntos.map((fileName: string, index: number) => {
            const fileNameOriginal = fileName.substring(37);
            return (
              <Box key={index} className={classes.itemContainer}>
                <Tooltip title={fileNameOriginal} placement="top">
                  <Chip
                    className={clsx(classes.chip)}
                    label={truncateText(fileNameOriginal)}
                    variant="outlined"
                    size="small"
                    onClick={() => handleClickArchivoAdjunto(fileName)}
                  />
                </Tooltip>
              </Box>
            );
          })}
      </>
    );
  };

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        {loading && <Box position="absolute" left={0} right={0}><LinearProgress color="secondary" /></Box>}
        <Box p={3} display="flex">
          <Button
            variant="contained"
            onClick={handleRefreshReporte}
            style={{ marginLeft: 10 }}
            startIcon={<RefreshIcon />}
          >
            {isDesktop && <Translate value="tabla.refrescar"/>}
          </Button>
          <Box display="flex">
            <FormControl className={classes.formControl} variant="standard">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  variant="inline"
                  className={classes.input}
                  id="filtro-desde"
                  label={<Translate value="tabla.desde"/>}
                  autoOk
                  format="dd/MM/yyyy"
                  value={desde ? dateParse(desde, FORMAT, new Date()) : null}
                  inputVariant="standard"
                  onChange={(date, value) => {
                    setDesde(value || null);
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  variant="inline"
                  className={classes.input}
                  id="filtro-hasta"
                  label={<Translate value="tabla.hasta"/>}
                  autoOk
                  format="dd/MM/yyyy"
                  value={hasta ? dateParse(hasta, FORMAT, new Date()) : null}
                  onChange={(date, value) => {
                    setHasta(value || null);
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    checked={checkVerAsistencias}
                    color="primary"
                    onChange={handleChangeVerAsistencias}
                  />
                }
                label={I18n.t('reporte.verAsistencias')}
                labelPlacement="start"
              />
            </FormControl>
          </Box>
        </Box>
        {(rows.length === 0 || rows === undefined || rows === null) && (
          <Typography variant="subtitle1" align="center">
            <Translate value="tabla.sinRegistrosReporte" />
          </Typography>
        )}
        {rows.length > 0 && (
          <React.Fragment>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      key="nro"
                      align="left"
                      style={{
                        minWidth: 60,
                        fontWeight: "bold",
                      }}
                    >
                      <Translate value="tabla.nro" />
                    </TableCell>
                    {columns.filter(c => !c.hidden).map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                          zIndex: 5,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .map((row: ReporteRow, rowIndex: number) => {
                      let resaltado = false;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={rowIndex}
                        >
                          <TableCell key="nro" className={resaltado ? classes.resaltado : undefined}>
                            <Typography>
                              {rowIndex + 1 + page * rowsPerPage}
                            </Typography>
                          </TableCell>
                          {columns.filter(c => !c.hidden).map(
                            (column: ReporteColumn, colIndex: number) => {
                              return (
                                <TableCell key={colIndex} className={resaltado ? classes.resaltado : undefined}>
                                  <div>{renderText(row[colIndex])}</div>
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 50, 100, 250]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={<Translate value="tabla.filasPorPagina" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${I18n.t('tabla.de')} ${count !== -1 ? count : `${I18n.t('tabla.masDe')} ${to}`}`}
            />
          </React.Fragment>
        )}
      </Paper>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: 'relative',
    minWidth: '600px',
  },
  container: {
    maxHeight: 'calc(100vh - 150px)',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'left',
    height: 0,
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'height .5s, marginTop .5s',
    background: '#eee',
    paddingLeft: '16px',
  },
  filterBoxOpen: {
    overflowX: 'auto',
    height: '64px',
  },
  btnAction: {
    margin: "0 3px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  formControl: {
    width: "160px",
    padding: "0 0 0 20px",
  },
  input: {
    margin: 0,
  },
  filterItem: {
    minWidth: '190px',
    margin: '0 16px',
  },
  resaltado: {
    backgroundColor: '#ffcccc',
  },
  itemContainer: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 4px 0 0',
  },
  chip: {
    zIndex: 2,
  },
  tablaDetalle: {
    '& td, th': {
      verticalAlign: 'top',
    }
  },
});

export default AsistenciasReporteSimple;
