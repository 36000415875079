import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
  LinearProgress,
  Divider,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import React, { useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Close } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { ICertificateEdit } from 'models/certificate_model';
import { RootState, useStateSelector } from 'store/reducers';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import { useDispatch } from 'react-redux';
import { generarCertificadoTemporalExistente } from 'store/actions/certificado';

type FormEditCertificateProps = {
  datosEditar: ICertificateEdit;
  loadingModal: boolean;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: ICertificateEdit, totalCertificados: number) => void;
};

const FormEditCertificate: React.FC<FormEditCertificateProps> = ({
  isModalOpen,
  loadingModal,
  datosEditar,
  handleClose,
  handleSave,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { locale } = useStateSelector((state: RootState) => state.i18n);

  const [loading, setLoading] = useState<boolean>(false);

  const { handleSubmit, control, formState, watch } = useForm<ICertificateEdit>(
    {
      defaultValues: {
        id: datosEditar.id,
        tipoCertificado: datosEditar.tipoCertificado,
        frase: datosEditar.frase,
        fechaCertificado: datosEditar.fechaCertificado,
        nombreCompleto: datosEditar.nombreCompleto,
      },
      shouldUnregister: false,
      mode: 'all',
    },
  );

  const onSubmit = (data: ICertificateEdit) => {
    handleSave(data, userVerification.length || 0);
  };

  const { userVerification } = useStateSelector(
    (state: RootState) => state.certificateState,
  );

  const handleGenerateExample = async () => {
    if (!watch().fechaCertificado || !watch().frase) {
      return;
    }

    setLoading(true);
    await dispatch(
      generarCertificadoTemporalExistente(
        {
          idCertificado: datosEditar.id,
          fecha: watch().fechaCertificado || '',
          frase: watch().frase || '',
        },
        `${datosEditar.nombreCompleto}.pdf`,
      ),
    );
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const renderFormulario = () => {
    return (
      <Box mb={3}>
        <Grid
          container
          spacing={2}
          style={{
            marginTop: '10px',
          }}
        >
          <Grid item xs={12} md={12}>
            <Typography
              gutterBottom
              variant="h6"
              style={{
                fontWeight: 'bold',
                marginBottom: 10,
              }}
            >
              <Translate value="tipoCertificado" />
            </Typography>
            <Controller
              name="tipoCertificado"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  disabled
                  {...field}
                  fullWidth
                  variant="filled"
                  required
                />
              )}
            />
          </Grid>

          <Grid
            container
            spacing={2}
            style={{ paddingInline: 10, marginTop: 10, width: '100%' }}
          >
            <Divider className={classes.divider} />
            <Grid item xs={12} md={6}>
              <Typography
                gutterBottom
                variant="h6"
                style={{
                  fontWeight: 'bold',
                  marginBottom: 10,
                }}
              >
                <Translate value="datosDestinatario" />
              </Typography>
              <Controller
                name="nombreCompleto"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    disabled
                    {...field}
                    fullWidth
                    variant="filled"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                gutterBottom
                variant="h6"
                style={{
                  fontWeight: 'bold',
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                <Translate value="fechaCertificado" />
              </Typography>

              <FormControl className={classes.formControl} variant="filled">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <Controller
                    name="fechaCertificado"
                    control={control}
                    defaultValue={null}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <KeyboardDatePicker
                        variant="inline"
                        id="fecha-certificado"
                        label=" "
                        autoOk
                        style={{
                          borderColor: 'black',
                          border: '1px',
                        }}
                        disabled={loading || loadingModal}
                        format="dd/MM/yyyy"
                        value={value}
                        inputVariant="standard"
                        onChange={(date) => {
                          onChange(date);
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                    rules={{ required: 'La fecha es requerida' }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                gutterBottom
                variant="h6"
                style={{
                  fontWeight: 'bold',
                  marginBottom: 10,
                }}
              >
                <Translate value="completarTextoCertificado" />{' '}
                {datosEditar.fraseInicial
                  ? `${datosEditar.fraseInicial}...`
                  : ''}
              </Typography>
              <Typography
                gutterBottom
                style={{
                  fontWeight: 'bold',
                }}
              >
                <Translate value="textAyudaCertificado" />
              </Typography>
              <Controller
                name="frase"
                control={control}
                defaultValue=""
                rules={{
                  required:
                    locale === 'es'
                      ? 'La frase es requerida'
                      : 'Der Satz ist erforderlich.',
                  maxLength: {
                    value: datosEditar.cantidadCaracteres || 150,
                    message:
                      locale === 'es'
                        ? `La frase no puede superar los ${
                            datosEditar.cantidadCaracteres || 150
                          } caracteres`
                        : `Der Satz darf nicht mehr als ${
                            datosEditar.cantidadCaracteres || 150
                          } Zeichen enthalten.`,
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <TextField
                      {...field}
                      disabled={loading || loadingModal}
                      fullWidth
                      variant="filled"
                      required
                    />
                    {!!error && (
                      <FormHelperText error>{error?.message}</FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      maxWidth={'lg'}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container justify="space-between">
          {I18n.t('editarCertificado')}
          <IconButton
            disabled={loadingModal || loading}
            size="small"
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>{renderFormulario()}</DialogContent>

        {(loadingModal || loading) && (
          <Box position="absolute" left={0} right={0}>
            <LinearProgress color="primary" />
          </Box>
        )}

        <DialogActions
          style={{
            margin: 10,
          }}
        >
          <Button
            disabled={loadingModal || loading}
            onClick={handleClose}
            className={classes.btnError}
            fullWidth
          >
            <Translate value="form.cancelar" />
          </Button>
          <Button
            disabled={loadingModal || loading}
            onClick={handleGenerateExample}
            className={classes.btnGenerate}
            fullWidth
          >
            <Translate value="previsualizarCertificado" />
          </Button>
          <Button
            disabled={loadingModal || !formState.isValid || loading}
            type="submit"
            className={classes.btnSave}
            fullWidth
          >
            <Translate value="form.guardar" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      borderRadius: '16px',
      '&::-webkit-scrollbar': {
        width: '5px',
      },
    },
    btnSave: {
      maxWidth: '200px',
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnGenerate: {
      maxWidth: '250px',
      backgroundColor: theme.palette.grey.A200,
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    btnError: {
      maxWidth: '160px',
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.light,
      },
    },
    customFileUpload: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 500,
      '& input[type="file"]': {
        display: 'none',
      },
      '& span': {
        marginLeft: '8px',
        textDecoration: 'underline',
        color: '#555',
      },
    },
    divider: {
      background: theme.palette.secondary.main,
      width: '100%',
      height: '1px',
    },
    formControl: {
      width: '100%',
    },
  }),
);

export default FormEditCertificate;
