import React, { ReactElement, useEffect } from "react";
import {
  Button,
  Box,
  Paper,
  makeStyles,
  FormControl,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  PictureAsPdf as PictureAsPdfIcon,
} from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { parse as dateParse } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { Translate } from "react-redux-i18n";
import moment from 'moment';
import { downloadReportePDF } from "repositories/reporte_repository";

const FORMAT = "dd/MM/yyyy";
const DEFAULT_DESDE = moment().format('DD/MM/YYYY');
const DEFAULT_HASTA = moment().format('DD/MM/YYYY');

type Props = {
  estudiante?: string | null;
  nombre?: string;
  nivelCurso?: string;
};

const KardexDetalleReporteSimple = ({
  estudiante,
  nombre,
  nivelCurso,
}: Props): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [loading, setLoading] = React.useState<boolean>(false);

  const [stateFilters, setStateFilters] = React.useState<any>({ estudiante });

  const [desde, setDesde] = React.useState<string | null>(DEFAULT_DESDE);
  const [hasta, setHasta] = React.useState<string | null>(DEFAULT_HASTA);

  const cargadoInicial = async () => {
    setStateFilters({ estudiante });
  };

  const handleClickPDF = async () => {
    const fechaDesde = moment(desde, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fechaHasta = moment(hasta, 'DD/MM/YYYY').format('YYYY-MM-DD');
    console.log('stateFilters = ', stateFilters);
    setLoading(true);
    const filename = `Detalle del kardex - ${nombre} ${Date.now()}`;
    let tipoReporte = 'KARDEX_DETALLE';
    if (nivelCurso && nivelCurso === 'KINDER') {
      tipoReporte = 'KARDEX_DETALLE_KINDER';
    }
    await downloadReportePDF(
      tipoReporte,
      fechaDesde,
      fechaHasta,
      stateFilters,
      filename,
    );
    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cargadoInicialCallback = React.useCallback(cargadoInicial, [estudiante, desde, hasta]);

  useEffect(() => {
    cargadoInicialCallback();
  }, [cargadoInicialCallback, estudiante, desde, hasta]);

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        {loading && <Box position="absolute" left={0} right={0}><LinearProgress color="secondary" /></Box>}
        <Box p={3} display="flex">
          <Box display="flex">
            <FormControl className={classes.formControl} variant="standard">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  variant="inline"
                  className={classes.input}
                  id="filtro-desde"
                  label={<Translate value="tabla.desde"/>}
                  autoOk
                  format="dd/MM/yyyy"
                  value={desde ? dateParse(desde, FORMAT, new Date()) : null}
                  inputVariant="standard"
                  onChange={(date, value) => {
                    setDesde(value || null);
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  variant="inline"
                  className={classes.input}
                  id="filtro-hasta"
                  label={<Translate value="tabla.hasta"/>}
                  autoOk
                  format="dd/MM/yyyy"
                  value={hasta ? dateParse(hasta, FORMAT, new Date()) : null}
                  onChange={(date, value) => {
                    setHasta(value || null);
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Box>
          <Button
            variant="contained"
            onClick={handleClickPDF}
            style={{ marginLeft: 'auto' }}
            startIcon={<PictureAsPdfIcon />}
            disabled={!estudiante || loading}
            color="primary"
          >
            {isDesktop && <Translate value="descargarPDF"/>}
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: 'relative',
    minWidth: '600px',
  },
  container: {
    // maxHeight: 440,
    // maxHeight: 'calc(100vh - 350px)',
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'left',
    height: 0,
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'height .5s, marginTop .5s',
    background: '#eee',
    paddingLeft: '16px',
  },
  filterBoxOpen: {
    overflowX: 'auto',
    height: '64px',
  },
  btnAction: {
    margin: "0 3px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  formControl: {
    width: "160px",
    padding: "0 0 0 20px",
  },
  input: {
    margin: 0,
  },
  filterItem: {
    minWidth: '190px',
    margin: '0 16px',
  },
  resaltado: {
    backgroundColor: '#ffcccc',
  },
  itemContainer: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 4px 0 0',
  },
  chip: {
    zIndex: 2,
  },
  tablaDetalle: {
    '& td, th': {
      verticalAlign: 'top',
    }
  },
});

export default KardexDetalleReporteSimple;
