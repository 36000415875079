import { AUTH_TYPES } from "../types";
import { firebaseAuth, registerLogout } from "../../plugins/firebase";
import { getSession } from "../../repositories/auth_repository";
import message from "../../plugins/utils/message";
import { I18n, setLocale } from 'react-redux-i18n';

export const loginWithEmailAndPassword = (email: string, password: string) => {
  return async ( dispatch: any ) => {
    try {
      const userCredential = await firebaseAuth.signInWithEmailAndPassword(email, password);
      const token = userCredential.user ? await userCredential.user.getIdToken() : null;
      const loginResponse: any = token ? await getSession(token) : null;
      if (!loginResponse || loginResponse.error) throw new Error(loginResponse.error);

      if (!loginResponse.rutas || loginResponse.rutas.length === 0) {
        throw new Error('El usuario no tiene permisos para iniciar sesión en la Aplicación Web, contáctese con el administrador')
      }

      localStorage.setItem('caleman_usuario', JSON.stringify(userCredential.user));
      dispatch( login(userCredential.user) );

      // RESTAURA EL IDIOMA DESDE EL BACKEND
      const newLocale = loginResponse.idioma === 'es_ES' ? 'es' : 'de';
      localStorage.setItem('locale', newLocale);
      dispatch(setLocale(newLocale));
      // RESTAURA EL IDIOMA DESDE EL BACKEND

    } catch (error: any) {
      if (error?.code === 'auth/wrong-password') {
        message.error(I18n.t('mensaje.contraseniaIncorrecta'))
      } else if (error?.code === 'auth/user-not-found') {
        message.error(I18n.t('mensaje.noExisteUsuario'))
      } else if (error?.code === 'auth/invalid-email') {
        message.error(I18n.t('mensaje.correoInvalido'))
      } else {
        message.error(error.message || I18n.t('mensaje.errorDesconocido'))
      }
    }
  }
}

export const recoveryPassword = (email: string, onSendResult: (result: boolean) => void) => {
  return async ( dispatch: any ) => {
    try {
      await firebaseAuth.sendPasswordResetEmail(email);
      return onSendResult(true);
    } catch (error: any) {
      if (error?.code === 'auth/wrong-password') {
        message.error(I18n.t('mensaje.contraseniaIncorrecta'))
      } else if (error?.code === 'auth/user-not-found') {
        message.error(I18n.t('mensaje.noExisteUsuario'))
      } else if (error?.code === 'auth/invalid-email') {
        message.error(I18n.t('mensaje.correoInvalido'))
      } else {
        message.error(error.message || I18n.t('mensaje.errorDesconocido'))
      }
      return onSendResult(false);
    }
  }
}

export const asyncLogout = () => {
  return async (dispatch: any) => {
    await firebaseAuth.signOut();
    registerLogout();
    localStorage.removeItem("caleman_usuario");

    dispatch(logout());
  };
};

export const login = (user: any) => ({
  type: AUTH_TYPES.LOGIN,
  payload: user,
});

export const logout = () => ({
  type: AUTH_TYPES.LOGOUT,
});
