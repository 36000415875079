import React, { ReactElement, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  FormControl,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  IconButton,
  useTheme,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import {
  FilterList as FilterListIcon,
  PictureAsPdf,
  Refresh as RefreshIcon,
  Search,
} from "@material-ui/icons";
import GridOnIcon from '@material-ui/icons/GridOn';
import { ReporteColumn, ReporteItem, ReporteModel, ReporteRow } from "models/reporte_model";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { parse as dateParse } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { TextFilter } from "./filters/TextFilter";
import { SelectFilter } from "./filters/SelectFilter";
import clsx from "clsx";
import { I18n, Translate } from "react-redux-i18n";
import moment from 'moment';
import { downloadReporteExcel, downloadReportePDF, getReporte } from "repositories/reporte_repository";
import { useIsMounted } from "hooks/useIsMounted";
import FormLibroClase from "./FormLibroClase";
import message from "../../../plugins/utils/message";
import { saveLibroClase } from "repositories/clase_repository";
import { MultiSelectFilter } from "./filters/MultiSelectFilter";

const FORMAT = "dd/MM/yyyy";
const DEFAULT_ROWS_PER_PAGE = 50;

const DEFAULT_DESDE = moment().format('DD/MM/YYYY');
const DEFAULT_HASTA = moment().format('DD/MM/YYYY');

const DEFAULT_DESDE_2 = moment().startOf('month').format('DD/MM/YYYY');
const DEFAULT_HASTA_2 = moment().endOf('month').format('DD/MM/YYYY');

const GESTION_ACTUAL = moment().year()

type Props = {
  tipoReporte: string;
  descargarExcel?: boolean;
  filtroExpandido?: boolean;
};

const ReporteSimple = ({ tipoReporte, descargarExcel, filtroExpandido }: Props): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [checkVerComoJefeCurso, setCheckVerComoJefeCurso] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const isMounted = useIsMounted();
  const [user, setUser] = React.useState<any>({});
  const [modalFormLibroClase, setModalFormLibroClase] = React.useState(false);
  const [libroClase, setLibroClase] = React.useState<any | null>(null);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [filterOpen, setFilterOpen] = React.useState(filtroExpandido);
  const [stateFilters, setStateFilters] = React.useState<any>({});

  const [desde, setDesde] = React.useState<string | null>(tipoReporte === 'LIBRO_CLASES_ACUMULADO' ? DEFAULT_DESDE_2 : DEFAULT_DESDE);
  const [hasta, setHasta] = React.useState<string | null>(tipoReporte === 'LIBRO_CLASES_ACUMULADO' ? DEFAULT_HASTA_2 : DEFAULT_HASTA);

  const [rows, setRows] = React.useState<ReporteRow[]>([]);
  const [columns, setColumns] = React.useState<ReporteColumn[]>([]);

  const EDIT_ICON = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9898 18.9533C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533H19.9898ZM16.0299 3.69906L17.5049 4.87078C18.1097 5.34377 18.513 5.96726 18.6509 6.62299C18.8101 7.3443 18.6403 8.0527 18.1628 8.66544L9.3764 20.0279C8.97316 20.5439 8.37891 20.8341 7.74222 20.8449L4.24039 20.8879C4.04939 20.8879 3.89021 20.7589 3.84777 20.5761L3.0519 17.1255C2.91395 16.4912 3.0519 15.8355 3.45514 15.3303L9.68413 7.26797C9.79025 7.13898 9.98126 7.11855 10.1086 7.21422L12.7297 9.29967C12.8994 9.43942 13.1329 9.51467 13.377 9.48242C13.8969 9.41792 14.2471 8.94493 14.1941 8.43969C14.1622 8.1817 14.0349 7.96671 13.8651 7.80546C13.812 7.76246 11.3183 5.76301 11.3183 5.76301C11.1591 5.63401 11.1273 5.39752 11.2546 5.23735L12.2415 3.95706C13.1541 2.78534 14.7459 2.67784 16.0299 3.69906Z"
        fill={theme.palette.warning.main}
      />
    </svg>
  );

  const cargarReporte = async (filters: any, page: number, rowsPerPage: number) => {
    setLoading(true);
    filters.verJefe = checkVerComoJefeCurso;
    const fechaDesde = moment(desde, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fechaHasta = moment(hasta, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const result = await getReporte(tipoReporte, fechaDesde, fechaHasta, filters, { _limit: 999999999, _page: 1 }) as ReporteModel;
    if (isMounted()) setLoading(false);
    if (result) {
      setRows(result.rows);
      setColumns(result.columns);
    }
  };
  const cargadoInicial = async () => {
    setStateFilters({});
    setPage(0);
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
    cargarReporte({}, 0, DEFAULT_ROWS_PER_PAGE);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cargadoInicialCallback = React.useCallback(cargadoInicial, [checkVerComoJefeCurso]);

  useEffect(() => {
    cargadoInicialCallback();
  }, [cargadoInicialCallback, checkVerComoJefeCurso]);

  useEffect(() => {
    const userLocal = window.localStorage.getItem('user');
    if (userLocal) {
      setUser(JSON.parse(userLocal))
    }
  }, []);

  const handleRefreshReporte = () => {
    cargarReporte(stateFilters, page, rowsPerPage);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = () => {
    const newFilterOpen = !filterOpen;
    setFilterOpen(newFilterOpen);
    if (!newFilterOpen) {
      setStateFilters({});
      setPage(0);
      cargarReporte({}, 0, rowsPerPage);
    }
  };

  const handleChangeFilterSelect = (name: string, value: string | number) => {
    const newFilters = { ...stateFilters, [name]: value };
    Object.keys(newFilters).forEach((key) => newFilters[key] === '' && delete newFilters[key]);
    setStateFilters(newFilters);
  };

  const handleSubmitFilter = () => {
    setPage(0);
    cargarReporte(stateFilters, 0, rowsPerPage);
  };

  const handleFilterCancel = () => {
    setStateFilters({});
    setPage(0);
    cargarReporte({}, 0, rowsPerPage);
  };

  const handleChangeVerComoJefeCurso = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCheckVerComoJefeCurso(checked);
  };

  const renderText = (value: ReporteItem): ReactElement => {
    if (typeof value !== 'string') {
      if (tipoReporte === 'LIBRO_CLASES_POR_SESION') return renderEditarLibroClase(value);
      if (tipoReporte === 'LIBRO_CLASES_ACUMULADO') return renderEditarLibroClaseAcumulado(value);
      return <></>;
    }
    return <>{value.split('|').map((line, lineIndex) => <Typography key={lineIndex}>{line}</Typography>)}</>;
  }

  const renderEditarLibroClase = (originalValue: ReporteItem): ReactElement => {
    if (typeof originalValue === "string") return <></>;
    const editable = originalValue.data.gestion === GESTION_ACTUAL
    const puedeEditar = editable && user?.rol === 'PROFESOR' && !checkVerComoJefeCurso;
    const llenarLibroMsg = puedeEditar ? I18n.t('tabla.llenarLibroClase') : '';
    return (
      <>
        {
          originalValue.data.clases.map((clase: any, lineIndex: number) => {
            return (
              <Box key={lineIndex} display="flex">
                <Typography key={lineIndex}>
                  {originalValue.value || llenarLibroMsg}{" "}
                  {puedeEditar && <Tooltip title="Editar libro de clases">
                    <IconButton
                      size="small"
                      onClick={() => editarLibroClase(originalValue, clase)}
                    >
                      {EDIT_ICON}
                    </IconButton>
                  </Tooltip>}
                </Typography>
              </Box>
            )
          })
        }
      </>
    );
  };

  const renderEditarLibroClaseAcumulado = (originalValue: ReporteItem): ReactElement => {
    if (typeof originalValue === "string") return <></>;
    if (!originalValue.data) return <></>;
    const editable = originalValue.data.gestion === GESTION_ACTUAL
    const puedeEditar = editable && user?.rol === 'PROFESOR' && !checkVerComoJefeCurso;
    return (
      <>
        {
          originalValue.data.clases.map((clase: any, lineIndex: number) => {
            return (
              <Box key={lineIndex} display="flex">
                <Typography>
                  {clase.fecha}{" "}
                  {puedeEditar && <Tooltip title="Editar libro de clases">
                    <IconButton
                      size="small"
                      onClick={() => editarLibroClase(originalValue, clase)}
                    >
                      {EDIT_ICON}
                    </IconButton>
                  </Tooltip>}
                </Typography>
              </Box>
            );
          })
        }
      </>
    );
  };

  const editarLibroClase = async (originalValue: ReporteItem, clase: any) => {
    if (typeof originalValue === 'string') return;
    const libroClase = {
      id: clase.id,
      avances: clase.avances,
      tarea: clase.tarea,
      tieneTareaRegistrada: clase.tieneTareaRegistrada,
      curso: originalValue.data.curso,
      cursoId: originalValue.data.cursoId,
      materia: originalValue.data.materia,
      fecha: clase.fecha,
    };
    setLibroClase(libroClase);
    handleClickOpen();
  }

  const handleClickExcel = async () => {
    stateFilters.verJefe = checkVerComoJefeCurso;
    const fechaDesde = moment(desde, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fechaHasta = moment(hasta, 'DD/MM/YYYY').format('YYYY-MM-DD');
    setLoading(true);
    await downloadReporteExcel(tipoReporte, fechaDesde, fechaHasta, stateFilters);
    setLoading(false);
  };

  const handleClickPDF = async () => {
    stateFilters.verJefe = checkVerComoJefeCurso;
    const fechaDesde = moment(desde, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const fechaHasta = moment(hasta, 'DD/MM/YYYY').format('YYYY-MM-DD');
    setLoading(true);
    await downloadReportePDF(tipoReporte, fechaDesde, fechaHasta, stateFilters);
    setLoading(false);
  };

  const handleClickOpen = () => {
    setModalFormLibroClase(true);
  };

  const handleClose = () => {
    cargarReporte(stateFilters, page, rowsPerPage);
    setModalFormLibroClase(false);
    setLibroClase(null);
  };

  const handleSaveLibroClase = async (data: any) => {
    setLoading(true);
    const result = await saveLibroClase(libroClase.id, data.avances, data.tarea);
    if (isMounted()) setLoading(false);
    if (result) {
      message.success('Libro de clases modificado exitosamente');
      cargarReporte(stateFilters, page, rowsPerPage);
      handleClose();
      return true;
    } else {
      return false;
    }
  }

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        {loading && <Box position="absolute" left={0} right={0}><LinearProgress color="secondary" /></Box>}
        <Box p={3} display="flex">
          {columns.some(col => col.filter) && <Button
            variant="contained"
            style={{ marginLeft: 10 }}
            onClick={handleFilter}
            disabled={loading}
          >
            <FilterListIcon />
          </Button>}
          <Button
            variant="contained"
            onClick={handleRefreshReporte}
            style={{ marginLeft: 10, minWidth: '150px' }}
            startIcon={<RefreshIcon />}
            disabled={loading}
          >
            <Translate value="tabla.refrescar"/>
          </Button>
          <Box display="flex">
            <FormControl className={classes.formControl} variant="standard">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  variant="inline"
                  className={classes.input}
                  id="filtro-desde"
                  label={<Translate value="tabla.desde"/>}
                  autoOk
                  format="dd/MM/yyyy"
                  value={desde ? dateParse(desde, FORMAT, new Date()) : null}
                  inputVariant="standard"
                  disabled={loading}
                  onChange={(date, value) => {
                    setDesde(value || null);
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  variant="inline"
                  className={classes.input}
                  id="filtro-hasta"
                  label={<Translate value="tabla.hasta"/>}
                  autoOk
                  format="dd/MM/yyyy"
                  value={hasta ? dateParse(hasta, FORMAT, new Date()) : null}
                  disabled={loading}
                  onChange={(date, value) => {
                    setHasta(value || null);
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            {user?.rol === 'PROFESOR' && <FormControl>
              <FormControlLabel
                value="start"
                style={{ padding: '0 16px' }}
                control={
                  <Checkbox
                    checked={checkVerComoJefeCurso}
                    color="primary"
                    onChange={handleChangeVerComoJefeCurso}
                  />
                }
                label={isDesktop ? I18n.t('reporte.verComoJefeCurso') : I18n.t('reporte.jefe')}
                labelPlacement="start"
              />
            </FormControl>}
          </Box>
          {(typeof descargarExcel === 'undefined' || descargarExcel) && <Box ml={'auto'} pl={2} minWidth={'450px'}>
              <Button
                variant="contained"
                style={{ minWidth: '200px' }}
                color="primary"
                onClick={handleClickExcel}
                startIcon={<GridOnIcon />}
                disabled={loading}
              >
                <Translate value="tabla.generarExcel"/>
              </Button>
              <Button
                variant="contained"
                style={{ minWidth: '200px', marginLeft: '24px' }}
                color="primary"
                onClick={handleClickPDF}
                startIcon={<PictureAsPdf />}
                disabled={loading}
              >
                <Translate value="descargarPDF"/>
              </Button>
            </Box>}
        </Box>
        {filterOpen && columns.some(col => col.filter) && (
          <div className={clsx(classes.filterBox, { [classes.filterBoxOpen]: filterOpen })}>
            <label><Translate value="tabla.buscarPor"/>: </label>
            <br />

            {columns.map((column, columnIndex) => {
              if (column.filter && column.filter.type === 'text') {
                return (
                  <Box key={columnIndex} className={classes.filterItem}>
                    <TextFilter
                      name={column.id as string}
                      label={column.label}
                      onChange={handleChangeFilterSelect}
                      value={stateFilters ? (stateFilters[String(column.id)] as string) : ''}
                      disabled={loading}
                    />
                  </Box>
                );
              }
              if (column.filter && column.filter.type === 'select') {
                return (
                  <Box key={columnIndex} className={classes.filterItem}>
                    <SelectFilter
                      name={column.id as string}
                      label={column.label}
                      options={column.filter.options || []}
                      onChange={handleChangeFilterSelect}
                      value={stateFilters ? (stateFilters[String(column.id)] as string) : ''}
                      disabled={loading}
                    />
                  </Box>
                );
              }
              if (column.filter && column.filter.type === 'multiselect') {
                return (
                  <Box key={columnIndex} className={classes.filterItem}>
                    <MultiSelectFilter
                      name={column.id as string}
                      label={column.label}
                      options={column.filter.options || []}
                      onChange={handleChangeFilterSelect}
                      value={stateFilters ? (stateFilters[String(column.id)] as string) : ''}
                      disabled={loading}
                    />
                  </Box>
                );
              }
              return <Box key={columnIndex}></Box>;
            })}

            <Box mr={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitFilter}
                disabled={loading}
              >
                <Search /> <Translate value="tabla.buscar"/>
              </Button>
            </Box>
            {(stateFilters && Object.keys(stateFilters).length > 0) && (
              <Button variant="contained" onClick={handleFilterCancel} disabled={loading}>
                <Translate value="form.cancelar"/>
              </Button>
            )}
          </div>
        )}
        {(rows.length === 0 || rows === undefined || rows === null) && (
          <Typography variant="subtitle1" align="center">
            <Translate value="tabla.sinRegistrosReporte" />
          </Typography>
        )}
        {rows.length > 0 && (
          <React.Fragment>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      key="nro"
                      align="left"
                      style={{
                        minWidth: 60,
                        fontWeight: "bold",
                      }}
                    >
                      <Translate value="tabla.nro" />
                    </TableCell>
                    {columns.filter(c => !c.hidden).map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: ReporteRow, rowIndex: number) => {
                      let resaltado = false;
                      if (tipoReporte === 'ASISTENCIAS_POR_MATERIA') {
                        resaltado = parseInt(String(row[10])) > 0;
                      }
                      if (tipoReporte === 'LIBRO_CLASES_POR_SESION') {
                        if (typeof row[8] === 'string') return <></>;
                        resaltado = !String(row[8].value) || String(row[8].value).trim().length === 0;
                      }
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={rowIndex}
                        >
                          <TableCell key="nro" className={resaltado ? classes.resaltado : undefined}>
                            <Typography>
                              {rowIndex + 1 + page * rowsPerPage}
                            </Typography>
                          </TableCell>
                          {columns.filter(c => !c.hidden).map(
                            (column: ReporteColumn, colIndex: number) => {
                              return (
                                <TableCell key={colIndex} className={resaltado ? classes.resaltado : undefined}>
                                  <div>{renderText(row[colIndex])}</div>
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 50, 100, 250]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={<Translate value="tabla.filasPorPagina" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${I18n.t('tabla.de')} ${count !== -1 ? count : `${I18n.t('tabla.masDe')} ${to}`}`}
            />
          </React.Fragment>
        )}
      </Paper>
      {modalFormLibroClase && (
        <FormLibroClase handleClose={handleClose} libroClase={libroClase} handleSave={handleSaveLibroClase} isModalOpen={modalFormLibroClase} />
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: 'relative',
  },
  container: {
    maxHeight: 'calc(100vh - 130px)',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'left',
    height: 0,
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'height .5s, marginTop .5s',
    background: '#eee',
    paddingLeft: '16px',
  },
  filterBoxOpen: {
    overflowX: 'auto',
    height: '64px',
  },
  btnAction: {
    margin: "0 3px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  formControl: {
    width: "200px",
    padding: "0 0 0 20px",
  },
  input: {
    margin: 0,
  },
  filterItem: {
    minWidth: '190px',
    margin: '0 16px',
  },
  resaltado: {
    backgroundColor: '#ffcccc',
    borderColor: 'grey',
  },
});

export default ReporteSimple;
