import React, { useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  LinearProgress,
  makeStyles,
  Grid,
  Collapse,
  createStyles,
  Theme,
  Chip,
  Tooltip,
  IconButton,
  useTheme
} from "@material-ui/core";
import { Refresh as RefreshIcon } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import GridOnIcon from '@material-ui/icons/GridOn';
// import PostAddIcon from '@material-ui/icons/PostAdd';
import { useDispatch } from "react-redux";
import {
  agregarPermiso,
  editarPermiso,
  masInfoPermiso,
  responderPermiso,
} from "../../store/actions/permiso_actions";
import { Permiso } from "../../models/permiso_model";
import MainLayout from "../../components/layout/MainLayout";
import clsx from "clsx";
import Util from "../../plugins/utils";
import FormPermiso from "./FormPermiso";
import { Translate, I18n } from 'react-redux-i18n';
import message from "../../plugins/utils/message";
import { Buscador } from "views/Kardex/components/Buscador";
import CConfirm from "components/core/CConfirm";
import moment from 'moment';
import { descargarReporteExcel } from "store/actions/reporte_actions";
import { buscarCorreo, listarPermisos } from "repositories/permiso_repository";
import FormMasInfo from "./FormMasInfo";
import { Alert, AlertTitle } from "@material-ui/lab";
import { agregarAltaMedica, masInfoAltaMedica, responderAltaMedica } from "store/actions/altaMedica_actions";
import { useIsMounted } from "hooks/useIsMounted";
import FormMasInfoAltaMedica from "./FormMasInfoAltaMedica";
import { AltaMedica } from "models/altaMedica_model";

const urlFile = process.env.REACT_APP_FILE_URL;

interface Column {
  id: "estudianteId" | "padre" | "motivo" | "estado" | "fecha";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

function buildColumns(): Column[] {
  return [
    { id: "estudianteId", label: I18n.t('estudiante') },
    { id: "padre", label: I18n.t('padre') },
    { id: "motivo", label: I18n.t('motivo') },
    { id: "estado", label: I18n.t('estado') },
    { id: "fecha", label: I18n.t('fechaSolicitud') }
  ];
}

const DEFAULT_ROWS_PER_PAGE = 5;
const GESTION_ACTUAL = moment().year()

const Permisos: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [estadoActual, setEstadoActual] = React.useState('Recibido');
  const [permiso, setPermiso] = React.useState<Permiso | null>(null);
  const [modalFormPermiso, setModalFormPermiso] = React.useState(false);
  const [modalFormMasInfo, setModalFormMasInfo] = React.useState(false);
  const [modalFormMasInfoAltaMedica, setModalFormMasInfoAltaMedica] = React.useState(false);
  const [estudiante, setEstudiante] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);
  const isMounted = useIsMounted();

  const [columns, setColumns] = React.useState(buildColumns());
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  const [confirmData, setConfirmData] = React.useState<any>();
  const [openConfirmAltaMedica, setOpenConfirmAltaMedica] = React.useState<boolean>(false);
  const [confirmDataAltaMedica, setConfirmDataAltaMedica] = React.useState<any>();
  const [permisos_v2, setPermisos] = React.useState<Permiso[]>([]);

  const cargarPermisos_v2 = async (estudiante: string | null, estado: string, page: number, rowsPerPage: number) => {
    setLoading(true);
    if (loading) return

    const filters: any = {
      _page: page + 1,
      _limit: rowsPerPage,
      estado,
    };
    if (estudiante) filters.estudiante = estudiante;

    const result = await listarPermisos(filters);
    if (isMounted()) setLoading(false);
    if (result) {
      setPermisos(result.rows);
      setCount(result.count);
    }
  };

  const cargadoInicial = async () => {
    setEstudiante(null);
    setEstadoActual('Recibido');
    setPage(0);
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
    cargarPermisos_v2(null, 'Recibido', 0, DEFAULT_ROWS_PER_PAGE);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cargadoInicialCallback = React.useCallback(cargadoInicial, []);

  useEffect(() => {
    cargadoInicialCallback();
  }, [cargadoInicialCallback]);

  const handleGetPermisos = (estado: string) => {
    setEstadoActual(estado);
    setPage(0)
    cargarPermisos_v2(estudiante, estado, 0, rowsPerPage);
  }

  const handleRefreshPermisos = () => {
    setColumns(buildColumns())
    cargarPermisos_v2(estudiante, estadoActual, page, rowsPerPage);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    cargarPermisos_v2(estudiante, estadoActual, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    cargarPermisos_v2(estudiante, estadoActual, 0, newRowsPerPage);
  };

  const handleClickOpen = () => {
    setModalFormPermiso(true);
  };

  const handleClose = () => {
    setModalFormPermiso(false);
    setPermiso(null);
  };

  // Observado
  const handleMasInfoPermiso = (permiso: Permiso) => {
    setPermiso(permiso);
    setModalFormMasInfo(true);
  };

  const handleMasInfoAltaMedica = (permiso: Permiso) => {
    setPermiso(permiso);
    setModalFormMasInfoAltaMedica(true);
  };

  const handleMasInfoClose = () => {
    setModalFormMasInfo(false);
    setModalFormMasInfoAltaMedica(false);
    setPermiso(null);
  };

  const handleSaveMasInfo = (data: Partial<Permiso>) => {
    if (!permiso?.id) return

    const dataToSave = {
      tipoObservacion: data.tipoObservacion,
      tipoObservacionOtro: data.tipoObservacionOtro,
      observacion: data.observacion,
      estado: 'Observado',
    } as Permiso;

    dispatch( masInfoPermiso(dataToSave, permiso?.id) );

    message.success(I18n.t(`informacionEnviadaCorrectamente`))

    setTimeout(() => {
      cargarPermisos_v2(estudiante, estadoActual, 0, rowsPerPage);
    }, 1000);

    handleMasInfoClose()
  }

  const handleSaveMasInfoAltaMedica = (data: Partial<AltaMedica>) => {
    if (!permiso?.altaMedica?.id) return

    const dataToSave = {
      tipoObservacion: data.tipoObservacion,
      observacion: data.observacion,
      estado: 'Observado',
    } as Permiso;

    dispatch( masInfoAltaMedica(dataToSave, permiso?.altaMedica?.id) );

    message.success(I18n.t(`informacionEnviadaCorrectamente`));

    setTimeout(() => {
      cargarPermisos_v2(estudiante, estadoActual, 0, rowsPerPage);
    }, 1000);

    handleMasInfoClose()
  }

  const handleSavePermiso = (data: Permiso) => {
    if (permiso?.id) {
      dispatch( editarPermiso({ ...data }, permiso.id) );
    } else {
      dispatch( agregarPermiso(data) );
    }

    message.success(permiso?.id ? I18n.t(`edicionPermisoExitoso`) : I18n.t(`creacionPermisoExitoso`))

    setTimeout(() => {
      cargarPermisos_v2(estudiante, estadoActual, 0, rowsPerPage);
    }, 1000);

    handleClose()
  }

  const handleEditPermiso = (permiso: Permiso) => {
    setPermiso(permiso);
    handleClickOpen();
  }

  const handleEditAltaMedica = (permiso: Permiso) => {
    const datos = {
      estado: "Recibido",
      fecha: "2022-01-01",
      adjuntos: 'd22f1258-f53e-4032-a31f-9c7447fa64a2-construction.png',
      permisoId: permiso.id,
    };
    dispatch(agregarAltaMedica(datos));
    message.success(`Alta médica de prueba creada exitosamente`);
  };

  const cambiarEstudiante = async (estudianteId: string) => {
    setEstudiante(estudianteId);
    setPage(0);
    cargarPermisos_v2(estudianteId, estadoActual, 0, rowsPerPage);
  };

  const handleCambiarEstadoPermiso = (_permiso: Permiso, _estado: string) => {
    setOpenConfirm(true);
    setConfirmData({ ..._permiso, estado: _estado });
  };

  // Aprobado, Rechazado, ELIMINADO
  const handleCambiarEstadoAltaMedica = (_permiso: Permiso, _estado: string) => {
    setOpenConfirmAltaMedica(true);
    setConfirmDataAltaMedica({ ..._permiso.altaMedica, estado: _estado });
  };

  const handleEnviarCorreo = (_permiso: Permiso) => {
    buscarCorreo(String(_permiso?.id)).then((result: any) => {
      if (result.destinatario) {
        const emailTo = result.destinatario;
        const emailSub = `${I18n.t('solicitudPermiso')}: ${estadoActual}`;
        const emailBody = '';
        window.open(`mailto:${emailTo}?subject=${emailSub}&body=${emailBody}`);
      }
    });
  };

  const handleClickDescargarReporteDiarioExcel = async () => {
    const tipoReporte = 'REPORTE_PERMISOS_DIARIO';
    const fechaDesde = moment().format('YYYY-MM-DD');
    const fechaHasta = moment().format('YYYY-MM-DD');
    const stateFilters = {
      estudiante: estudiante ? estudiante : undefined,
      estado: estadoActual,
    };
    dispatch(descargarReporteExcel(tipoReporte, fechaDesde, fechaHasta, stateFilters));
  };

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <div className={classes.box}>
          <Box>
            <button
              type="button"
              onClick={() => (handleGetPermisos('Recibido'))}
              className={clsx(classes.boxItem, { [classes.boxItemSelected]: estadoActual === 'Recibido' })}><Translate value="recibidas" /></button>
          </Box>
          <Box><button
            type="button"
            onClick={() => (handleGetPermisos('Aprobado'))}
            className={clsx(classes.boxItem, { [classes.boxItemSelected]: estadoActual === 'Aprobado' })}><Translate value="aprobadas" /></button>
          </Box>
          <Box>
            <button
              type="button"
              onClick={() => (handleGetPermisos('Observado'))}
              className={clsx(classes.boxItem, { [classes.boxItemSelected]: estadoActual === 'Observado' })}><Translate value="observadas" /></button>
          </Box>
          <Box>
            <button
              type="button"
              onClick={() => (handleGetPermisos('Rechazado'))}
              className={clsx(classes.boxItem, { [classes.boxItemSelected]: estadoActual === 'Rechazado' })}><Translate value="rechazadas" /></button>
          </Box>
          {/* <Box>
            <button
              type="button"
              onClick={() => (handleGetPermisos('ELIMINADO'))}
              className={clsx(classes.boxItem, { [classes.boxItemSelected]: estadoActual === 'ELIMINADO' })}><Translate value="eliminadas" /></button>
          </Box> */}
        </div>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <React.Fragment>
            <Paper className={classes.root}>
              {loading && <Box position="absolute" left={0} right={0}><LinearProgress color="secondary" /></Box>}
              <Box p={3} display='flex' minWidth={'1080px'} width={'1080px'}>
                <Button variant='contained' onClick={handleClickOpen} style={{ marginLeft: 10 }} startIcon={<AddIcon />} color="primary">
                    <Translate value="agregarPermiso" />
                </Button>
                <Button variant='contained' onClick={() => handleRefreshPermisos()} style={{ marginLeft: 10 }} startIcon={<RefreshIcon />}>
                    <Translate value="refrescar" />
                </Button>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height={'40px'}
                  ml={5}
                >
                  <Buscador onSearchClick={cambiarEstudiante} />
                </Box>
                {estadoActual === 'Aprobado' && <Box style={{ marginLeft: 'auto' }}>
                  <Button
                    variant="contained"
                    style={{ minWidth: '200px' }}
                    color="primary"
                    onClick={handleClickDescargarReporteDiarioExcel}
                    startIcon={<GridOnIcon />}
                  >
                    <Translate value="tabla.generarExcelPermisos"/>
                  </Button>
                </Box>}
              </Box>
              {(
                <React.Fragment>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(permisos_v2.length === 0 ||
                          permisos_v2 === undefined ||
                          permisos_v2 === null) && (
                          <TableRow>
                            <TableCell colSpan={999}>
                              <Typography variant="subtitle1" align="center">
                                <Translate value="sinRegistroPermisos" />
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        {permisos_v2
                          .map((row: Permiso) => (
                            <Row
                              key={row.id}
                              row={row}
                              editable={row.curso?.gestion === GESTION_ACTUAL}
                              estadoActual={estadoActual}
                              handleEnviarCorreo={handleEnviarCorreo}
                              handleEditPermiso={handleEditPermiso}
                              handleCambiarEstadoPermiso={handleCambiarEstadoPermiso}
                              handleMasInfoPermiso={handleMasInfoPermiso}
                              handleEditAltaMedica={handleEditAltaMedica}
                              handleCambiarEstadoAltaMedica={handleCambiarEstadoAltaMedica}
                              handleMasInfoAltaMedica={handleMasInfoAltaMedica}
                              esBandejaEntrada={estadoActual === 'Recibido'}
                            />
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 50, 100, 250]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </React.Fragment>
              )}
            </Paper>
          </React.Fragment>
        </Grid>
      </Grid>
      { modalFormPermiso && (
        <FormPermiso handleClose={handleClose} permiso={permiso} handleSave={handleSavePermiso} isModalOpen={modalFormPermiso} />
      )}
      { modalFormMasInfo && (
        <FormMasInfo handleClose={handleMasInfoClose} permiso={permiso} handleSave={handleSaveMasInfo} isModalOpen={modalFormMasInfo} />
      )}
      { modalFormMasInfoAltaMedica && (
        <FormMasInfoAltaMedica handleClose={handleMasInfoClose} altaMedica={permiso?.altaMedica || null} handleSave={handleSaveMasInfoAltaMedica} isModalOpen={modalFormMasInfoAltaMedica} />
      )}
      <CConfirm
        openConfirm={openConfirm}
        handleCancel={() => {
          setOpenConfirm(false);
          setConfirmData(null);
        }}
        handleConfirm={() => {
          dispatch( responderPermiso(confirmData, confirmData.id) );
          setTimeout(() => {
            cargarPermisos_v2(estudiante, estadoActual, 0, rowsPerPage);
          }, 500);
          setOpenConfirm(false);
        }}
        message={
          confirmData?.estado === "Aprobado" ? (
            <>
              <Typography><Translate value="confirm.deseaAprobarPermiso" /></Typography>
            </>
          ) : confirmData?.estado === "Rechazado" ? (
            <>
              <Typography><Translate value="confirm.deseaRechazarPermiso" /></Typography>
            </>
          ) : confirmData?.estado === "Observado" ? (
            <>
              <Typography><Translate value="confirm.deseaSolicitarMasInformacion" /></Typography>
            </>
          ) : confirmData?.estado === "ELIMINADO" ? (
            <>
              <Typography gutterBottom><Translate value="confirm.deseaEliminarPermiso" /></Typography>
            </>
          ) : (
            <></>
          )
        }
      />
      <CConfirm
        openConfirm={openConfirmAltaMedica}
        handleCancel={() => {
          setOpenConfirmAltaMedica(false);
          setConfirmDataAltaMedica(null);
        }}
        handleConfirm={() => {
          dispatch( responderAltaMedica(confirmDataAltaMedica, confirmDataAltaMedica.id) );
          setTimeout(() => {
            cargarPermisos_v2(estudiante, estadoActual, 0, rowsPerPage);
          }, 500);
          setOpenConfirmAltaMedica(false);
        }}
        message={
          confirmDataAltaMedica?.estado === "Aprobado" ? (
            <>
              <Typography><Translate value="confirm.deseaAprobarAltaMedica" /></Typography>
            </>
          ) : confirmDataAltaMedica?.estado === "Rechazado" ? (
            <>
              <Typography><Translate value="confirm.deseaRechazarAltaMedica" /></Typography>
            </>
          ) : confirmDataAltaMedica?.estado === "Observado" ? (
            <>
              <Typography><Translate value="confirm.deseaSolicitarMasInformacion" /></Typography>
            </>
          ) : (
            <></>
          )
        }
      />
    </MainLayout>
  );
};

function Row(props: {
  row: Permiso;
  editable: boolean;
  estadoActual: string;
  handleEnviarCorreo: (permiso: Permiso) => void;
  handleEditPermiso: (permiso: Permiso) => void;
  handleEditAltaMedica: (permiso: Permiso) => void;
  handleCambiarEstadoPermiso: (permiso: Permiso, etado: string) => void;
  handleMasInfoPermiso: (permiso: Permiso) => void;
  handleCambiarEstadoAltaMedica: (permiso: Permiso, etado: string) => void;
  handleMasInfoAltaMedica: (permiso: Permiso) => void;
  esBandejaEntrada: boolean;
}) {
  const {
    row,
    editable,
    esBandejaEntrada,
    handleEnviarCorreo,
    handleEditPermiso,
    // handleEditAltaMedica,
    handleCambiarEstadoPermiso,
    handleMasInfoPermiso,
    handleCambiarEstadoAltaMedica,
    handleMasInfoAltaMedica,
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();

  const renderObservacion = (row: Permiso) => {
    const tipoObservacionOtroParamId = '9'
    if (row.tipoObservacion === tipoObservacionOtroParamId) {
      return (
        <Box pb={2}>
          <Alert severity="warning">
            <AlertTitle><strong>{row.tipoObservacionOtro}</strong></AlertTitle>
            {row.observacion}
          </Alert>
        </Box>
      );
    }
    return (
      <Box pb={2}>
        <Alert severity="warning">
          <AlertTitle><strong>{row.tipoObservacionParam?.nombre}</strong></AlertTitle>
          {row.observacion}
        </Alert>
      </Box>
    );
  };

  const renderAdjuntos = (adjuntos: string) => {
    return (
      <ul className={classes.adjuntos}>
        {(String(adjuntos)).split(",").map((item, itemIndex) => (
          <li key={itemIndex}>
            <a href={urlFile + '/' + item} target="_blank" rel="noreferrer">
              { item.indexOf('.pdf') !== -1 && (
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.8534 0C16.0668 0 17.9913 1.95143 18 5.20971V14.7903C18 18.0486 16.0842 20 12.8621 20H10.0058C9.6395 19.959 9.36224 19.6452 9.36224 19.2715C9.36224 18.8978 9.6395 18.584 10.0058 18.543H12.8534C15.3091 18.543 16.5457 17.2804 16.5457 14.7903V5.20971C16.5457 2.71965 15.3091 1.45695 12.8534 1.45695H5.13788C2.68215 1.45695 1.43686 2.71965 1.43686 5.20971V14.7903C1.43686 17.2804 2.68215 18.543 5.13788 18.543C5.50419 18.584 5.78145 18.8978 5.78145 19.2715C5.78145 19.6452 5.50419 19.959 5.13788 20C1.92453 20 0 18.0486 0 14.7903V5.20971C0 1.9426 1.92453 0 5.13788 0H12.8534ZM12.5922 13.5099C12.9865 13.5099 13.3062 13.8341 13.3062 14.234C13.3062 14.6339 12.9865 14.9581 12.5922 14.9581H5.38171C4.98734 14.9581 4.66763 14.6339 4.66763 14.234C4.66763 13.8341 4.98734 13.5099 5.38171 13.5099H12.5922ZM12.5922 9.27152C12.8677 9.24072 13.1364 9.37244 13.2837 9.61057C13.431 9.84871 13.431 10.1513 13.2837 10.3894C13.1364 10.6276 12.8677 10.7593 12.5922 10.7285H5.38171C5.01541 10.6875 4.73814 10.3737 4.73814 10C4.73814 9.62632 5.01541 9.31248 5.38171 9.27152H12.5922ZM8.13353 5.04194C8.49983 5.0829 8.7771 5.39674 8.7771 5.77042C8.7771 6.1441 8.49983 6.45794 8.13353 6.4989H5.39042C5.02411 6.45794 4.74685 6.1441 4.74685 5.77042C4.74685 5.39674 5.02411 5.0829 5.39042 5.04194H8.13353Z" fill="#200E32"/>
                </svg>
              )}
              { item.indexOf('.pdf') === -1 && (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.3 0C17.686 0 19.96 2.371 19.96 5.899V14.05C19.96 17.578 17.686 19.949 14.3 19.949H10.98C10.566 19.949 10.23 19.613 10.23 19.199C10.23 18.785 10.566 18.449 10.98 18.449H14.3C16.827 18.449 18.46 16.722 18.46 14.05V5.899C18.46 3.226 16.827 1.5 14.3 1.5H5.65C3.129 1.5 1.5 3.226 1.5 5.899V14.05C1.5 16.722 3.129 18.449 5.65 18.449H6.371C6.785 18.449 7.121 18.785 7.121 19.199C7.121 19.613 6.785 19.949 6.371 19.949H5.65C2.271 19.949 0 17.578 0 14.05V5.899C0 2.371 2.271 0 5.65 0H14.3ZM13.2915 9.5434C13.9825 9.5224 14.6585 9.7854 15.1405 10.2824L17.2175 12.4234C17.5055 12.7214 17.4985 13.1964 17.2005 13.4844C16.9035 13.7734 16.4285 13.7644 16.1405 13.4684L14.0635 11.3274C13.8755 11.1334 13.6295 11.0244 13.3525 11.0424C13.0825 11.0534 12.8395 11.1754 12.6675 11.3844L10.4385 14.0964C10.0455 14.5744 9.4655 14.8644 8.8475 14.8914C8.2285 14.9044 7.6265 14.6804 7.1925 14.2394L6.3065 13.3354C6.1765 13.2014 6.0115 13.0834 5.8275 13.1294C5.6465 13.1304 5.4795 13.2034 5.3555 13.3334L3.8255 14.9484C3.6785 15.1044 3.4805 15.1824 3.2815 15.1824C3.0965 15.1824 2.9105 15.1144 2.7655 14.9774C2.4655 14.6924 2.4525 14.2174 2.7375 13.9164L4.2665 12.3024C4.6745 11.8704 5.2265 11.6314 5.8215 11.6294C6.3955 11.6674 6.9705 11.8624 7.3825 12.2904L8.2635 13.1884C8.4005 13.3284 8.5805 13.4064 8.7815 13.3924C8.9775 13.3834 9.1545 13.2954 9.2795 13.1434L11.5085 10.4314C11.9485 9.8964 12.5985 9.5724 13.2915 9.5434ZM6.5603 4.6296C7.9403 4.6296 9.0633 5.7526 9.0633 7.1336C9.0633 8.5146 7.9403 9.6376 6.5603 9.6376C5.1793 9.6376 4.0563 8.5146 4.0563 7.1336C4.0563 5.7526 5.1793 4.6296 6.5603 4.6296ZM6.5603 6.1296C6.0063 6.1296 5.5563 6.5796 5.5563 7.1336C5.5563 7.6876 6.0063 8.1376 6.5603 8.1376C7.1133 8.1376 7.5633 7.6876 7.5633 7.1336C7.5633 6.5796 7.1133 6.1296 6.5603 6.1296Z" fill="#323232"/>
                </svg>
              )}
              <span className={classes.link}>{item.substring(37, item.length)}</span>
              <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8611 9.14147C16.666 8.90748 16.295 8.7616 15.8915 8.7616H9.60854V0.734294C9.60854 0.328964 9.11193 0 8.50004 0C7.88815 0 7.39154 0.328964 7.39154 0.734294V8.7616L1.10858 8.7616C0.703606 8.7616 0.332628 8.90748 0.139011 9.14147C-0.0575638 9.37547 -0.0442619 9.66135 0.170048 9.88752L7.56151 17.6573C7.76548 17.8708 8.11872 18 8.50004 18C8.88136 18 9.2346 17.8708 9.43857 17.6573L16.83 9.88752C16.9424 9.76807 17 9.63198 17 9.49589C17 9.37449 16.9527 9.25211 16.8611 9.14147Z" fill="#323232"/>
              </svg>
            </a>
          </li>
        ))}
      </ul>
    );
  };

  const renderAltaMedica = (row: AltaMedica) => {
    return (
      <Box pb={2}>
        {row.estado === "Aprobado" && (
          <Alert severity="success">
            <AlertTitle>
              <strong><Translate value="solicitudAltaMedicaAprobada" /></strong>
            </AlertTitle>
            <table className={classes.tablaDetalle}>
              <tbody>
                <tr>
                  <th style={{ width: '220px' }}><Translate value="fechaSolicitud" />:</th>
                  <td>{row.fechaSolicitudFormato}</td>
                </tr>
                <tr>
                  <th style={{ width: '220px' }}><Translate value="fechaAltaMedicaSolicitada" />:</th>
                  <td>{row.fechaFormato}</td>
                </tr>
              </tbody>
            </table>
            {row.adjuntos && renderAdjuntos(String(row.adjuntos))}
          </Alert>
        )}
        {row.estado === "Recibido" && (
          <Alert severity="info">
            <AlertTitle>
              <strong><Translate value="solicitudAltaMedicaRecibida" /></strong>
            </AlertTitle>
            <table className={classes.tablaDetalle}>
              <tbody>
                <tr>
                  <th style={{ width: '220px' }}><Translate value="fechaSolicitud" />:</th>
                  <td>{row.fechaSolicitudFormato}</td>
                </tr>
                <tr>
                  <th style={{ width: '220px' }}><Translate value="fechaAltaMedicaSolicitada" />:</th>
                  <td>{row.fechaFormato}</td>
                </tr>
              </tbody>
            </table>
            {row.adjuntos && renderAdjuntos(String(row.adjuntos))}
          </Alert>
        )}
        {row.estado === "Observado" && (
          <Alert severity="warning">
            <AlertTitle>
              <strong><Translate value="solicitudAltaMedicaObservada" /></strong>
            </AlertTitle>
            <table className={classes.tablaDetalle}>
              <tbody>
                <tr>
                  <th style={{ width: '220px' }}><Translate value="fechaSolicitud" />:</th>
                  <td>{row.fechaSolicitudFormato}</td>
                </tr>
                <tr>
                  <th style={{ width: '220px' }}><Translate value="fechaAltaMedicaSolicitada" />:</th>
                  <td>{row.fechaFormato}</td>
                </tr>
                {row.tipoObservacionParam?.nombre && <tr>
                  <th style={{ width: '220px' }}><Translate value="motivoObservacion" />:</th>
                  <td>{row.tipoObservacionParam?.nombre}</td>
                </tr>}
                {row.observacion && <tr>
                  <th style={{ width: '220px' }}><Translate value="observacion" />:</th>
                  <td>{row.observacion}</td>
                </tr>}
              </tbody>
            </table>
            {row.adjuntos && renderAdjuntos(String(row.adjuntos))}
          </Alert>
        )}
        {row.estado === "Rechazado" && (
          <Alert severity="error">
            <AlertTitle>
              <strong><Translate value="solicitudAltaMedicaRechazada" /></strong>
            </AlertTitle>
            <table className={classes.tablaDetalle}>
              <tbody>
                <tr>
                  <th style={{ width: '220px' }}><Translate value="fechaSolicitud" />:</th>
                  <td>{row.fechaSolicitudFormato}</td>
                </tr>
                <tr>
                  <th style={{ width: '220px' }}><Translate value="fechaAltaMedicaSolicitada" />:</th>
                  <td>{row.fechaFormato}</td>
                </tr>
                {row.tipoObservacionParam?.nombre && <tr>
                  <th style={{ width: '220px' }}><Translate value="motivoObservacion" />:</th>
                  <td>{row.tipoObservacionParam?.nombre}</td>
                </tr>}
                {row.observacion && <tr>
                  <th style={{ width: '220px' }}><Translate value="observacion" />:</th>
                  <td>{row.observacion}</td>
                </tr>}
              </tbody>
            </table>
            {row.adjuntos && renderAdjuntos(String(row.adjuntos))}
          </Alert>
        )}
      </Box>
    );
  };

  const renderPermisoParcial = () => {
    return (
      <Box pb={2}>
        <Alert severity="warning">
          <AlertTitle>
            <Translate value="permisoParcialNoAfectaAsistencia" />
          </AlertTitle>
        </Alert>
      </Box>
    );
  }

  const renderPermisoKinder = () => {
    return (
      <Box pb={2}>
        <Alert severity="warning">
          <AlertTitle>
            <Translate value="permisoAprobadoKinder" />
          </AlertTitle>
        </Alert>
      </Box>
    )
  }

  const textoTraducido: any = {
    Recibido: I18n.t('tabla.recibido'),
    Aprobado: I18n.t('tabla.aprobado'),
    Observado: I18n.t('tabla.observado'),
    Rechazado: I18n.t('tabla.rechazado'),
  }

  return (
    <React.Fragment>
    <TableRow
      className={clsx(classes.rowItem, { [classes.estadoRecibido]: row.estado === 'Recibido' })}
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      onClick={() => setOpen(!open)}
    >
      <TableCell>
        <Typography>{Util.buildDisplayName(row.usuario)}</Typography>
        <Typography variant="caption"><Translate value="curso" />: <strong>{row.curso?.codigoCurso} - {row.curso?.gestion}</strong></Typography>
      </TableCell>
      <TableCell>
        <Typography>{Util.buildDisplayName(row.usuarioPadre)}</Typography>
        <Typography variant="caption"><strong>{row.padre?.codigoPadre}</strong></Typography>
      </TableCell>
      <TableCell align="left"><Translate value={`opcion.MOTIVO_${row.motivo}`} /></TableCell>
      <TableCell align='left'>
        <Chip
          label={textoTraducido[row.estado]}
          color="primary"
          style={{
            backgroundColor:
              row.estado === "Aprobado"
                ? theme.palette.success.main
                : row.estado === "Observado"
                ? theme.palette.warning.main
                : row.estado === "Rechazado"
                ? theme.palette.error.main
                : undefined,
          }}
        />
        {row.altaMedica && <Box mt={1}><Chip
          size="small"
          label={`Alta médica: ${row.altaMedica.estado}`}
          color="primary"
          style={{
            backgroundColor:
              row.altaMedica.estado === "Aprobado"
                ? theme.palette.success.main
                : row.altaMedica.estado === "Observado"
                ? theme.palette.warning.main
                : row.altaMedica.estado === "Rechazado"
                ? theme.palette.error.main
                : row.altaMedica.estado === "Recibido"
                ? theme.palette.primary.main
                : undefined,
          }}
        /></Box>}
      </TableCell>
      <TableCell align='left'>
        {row.fechaFormato}
        {row.creadoPorMonitor && <Tooltip title={<><Translate value="creadoPor" /> <>Monitor</></>}>
          <Chip label='M' color="primary" style={{ marginLeft: '5px' }}/>
        </Tooltip>}
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell style={{ padding: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit style={{ border: '1px solid #FAE268', padding: '0 10px' }}>
          <Box margin={1}>
            {typeof row.observacion === 'string' && renderObservacion(row)}
            {row.altaMedica && renderAltaMedica(row.altaMedica)}
            {row.permisoParcial && !row.esPermisoKinder && renderPermisoParcial()}
            {row.esPermisoKinder && renderPermisoKinder()}

            <table className={classes.tablaDetalle}>
              <tbody>
                <tr>
                  <th style={{ width: '160px' }}><Translate value="curso" />:</th>
                  <td>{row.curso?.codigoCurso || '-'} - {row.curso?.gestion}</td>
                </tr>
                <tr>
                  <th style={{ width: '160px' }}><Translate value="remitente" />:</th>
                  <td>  
                    {row.esPermisoKinder 
                          ? `Profesor(a) ${Util.buildDisplayName(row.usuarioCreacion)}` 
                          : Util.buildDisplayName(row.usuarioPadre)}
                  </td>
                </tr>
                <tr>
                  <th><Translate value="fechaRecepcion" />:</th>
                  <td>
                    {row.fechaFormato}
                    {row.creadoPorMonitor && <Tooltip title={<><Translate value="creadoPor" /> <>Monitor</></>}>
                      <Chip label='M' color="primary" style={{ marginLeft: '5px' }} size="small"/>
                    </Tooltip>}
                  </td>
                </tr>
                <tr>
                  <th><Translate value="motivo" />:</th>
                  <td><Translate value={`opcion.${row.motivo}`} /> {row.permisoParcial && !row.esPermisoKinder && <strong>-- <Translate value="tienePermisoParcial" /></strong>}</td>
                </tr>
                <tr>
                  <th><Translate value="comentario" />:</th>
                  <td><span className={classes.descripcion}>{row.descripcion}</span></td>
                </tr>
                <tr>
                  <th><Translate value="fechaPermiso" />:</th>
                  { row.fechaInicioFormato === row.fechaFinFormato ? (
                    <td>{row.fechaInicioFormato}</td>
                  ) : (
                    <td>{row.fechaInicioFormato} al {row.fechaFinFormato}</td>
                  )}
                </tr>
                {row.permisoParcial && <tr>
                  <th><Translate value="horaPermiso" />:</th>
                  <td>{row.horaInicioFormato} {!row.esPermisoKinder ? `hasta ${row.horaFinFormato}` : ''}</td>
                </tr>}
                <tr>
                  <td>
                  {!['Rechazado', 'ELIMINADO'].includes(row.estado) && <Box display='flex'>
                    <Tooltip title="Editar permiso">
                      <IconButton
                        size='small'
                        disabled={!editable}
                        onClick={() => handleEditPermiso(row)}
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M19.9898 18.9533C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533H19.9898ZM16.0299 3.69906L17.5049 4.87078C18.1097 5.34377 18.513 5.96726 18.6509 6.62299C18.8101 7.3443 18.6403 8.0527 18.1628 8.66544L9.3764 20.0279C8.97316 20.5439 8.37891 20.8341 7.74222 20.8449L4.24039 20.8879C4.04939 20.8879 3.89021 20.7589 3.84777 20.5761L3.0519 17.1255C2.91395 16.4912 3.0519 15.8355 3.45514 15.3303L9.68413 7.26797C9.79025 7.13898 9.98126 7.11855 10.1086 7.21422L12.7297 9.29967C12.8994 9.43942 13.1329 9.51467 13.377 9.48242C13.8969 9.41792 14.2471 8.94493 14.1941 8.43969C14.1622 8.1817 14.0349 7.96671 13.8651 7.80546C13.812 7.76246 11.3183 5.76301 11.3183 5.76301C11.1591 5.63401 11.1273 5.39752 11.2546 5.23735L12.2415 3.95706C13.1541 2.78534 14.7459 2.67784 16.0299 3.69906Z" fill={editable ? theme.palette.warning.main: theme.palette.grey[300]}/>
                        </svg>
                      </IconButton>
                    </Tooltip>
                  </Box>}
                  {/* {['Aprobado'].includes(row.estado) && row.motivo === 'SALUD' && <Box display='flex'>
                    <Tooltip title="Adjuntar alta médica">
                      <IconButton size='small' onClick={() => handleEditAltaMedica(row)}>
                        <PostAddIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>} */}
                  </td>
                </tr>
              </tbody>
            </table>
            {row.adjuntos && renderAdjuntos(String(row.adjuntos))}
            {(!esBandejaEntrada || !(esBandejaEntrada && row.altaMedica && row.altaMedica.estado === 'Recibido')) && <div className={classes.btnContainer}>
              {(['Recibido', 'Aprobado', 'Observado', 'Rechazado'].includes(row.estado)) && (
                <Button
                  className={classes.btnPrimary}
                  onClick={() => handleCambiarEstadoPermiso(row, 'ELIMINADO')}
                >
                  <DeleteIcon />
                </Button>
              )}
              {(['Aprobado', 'Observado'].includes(row.estado)) && (
                <Button
                  className={classes.btnSendMail}
                  onClick={() => handleEnviarCorreo(row)}>
                  <Translate value="enviarCorreo" />
                </Button>
              )}
              {(['Recibido', 'Observado'].includes(row.estado)) && (
                <Button
                  className={editable ? classes.btnCancel : classes.btnGrey}
                  disabled={!editable}
                  onClick={() => handleCambiarEstadoPermiso(row, 'Rechazado')}>
                  <Translate value="rechazar" />
                </Button>
              )}
              {(['Recibido'].includes(row.estado)) && (
                <Button
                  className={editable ? classes.btnWarn : classes.btnGrey}
                  disabled={!editable}
                  onClick={() => handleMasInfoPermiso(row)}>
                  <Translate value="solicitarInfo" />
                </Button>
              )}
              {(['Recibido', 'Observado', 'Rechazado'].includes(row.estado)) && (
                <Button
                  className={editable ? classes.btnSave : classes.btnGrey}
                  disabled={!editable}
                  onClick={() => handleCambiarEstadoPermiso(row, 'Aprobado')}>
                  <Translate value="aprobar" />
                </Button>
              )}
              {(['Aprobado'].includes(row.estado)) && (
                <Button
                  className={editable ? classes.btnCancel : classes.btnGrey}
                  disabled={!editable}
                  onClick={() => handleCambiarEstadoPermiso(row, 'Rechazado')}>
                  <Translate value="rechazar" />
                </Button>
              )}
            </div>}
            {esBandejaEntrada && row.altaMedica && row.altaMedica.estado === 'Recibido' && <div className={classes.btnContainer}>
              {(['Recibido', 'Aprobado', 'Observado', 'Rechazado'].includes(row.altaMedica.estado)) && (
                <Button
                  className={editable ? classes.btnPrimary : classes.btnGrey}
                  disabled={!editable}
                  onClick={() => handleCambiarEstadoAltaMedica(row, 'ELIMINADO')}
                >
                  <DeleteIcon />
                </Button>
              )}
              {(['Aprobado', 'Observado'].includes(row.altaMedica.estado)) && (
                <Button
                  className={editable ? classes.btnSendMail : classes.btnGrey}
                  disabled={!editable}
                  onClick={() => handleEnviarCorreo(row)}>
                  <Translate value="enviarCorreo" />
                </Button>
              )}
              {(['Recibido'].includes(row.altaMedica.estado)) && (
                <Button
                  className={editable ? classes.btnCancel : classes.btnGrey}
                  disabled={!editable}
                  onClick={() => handleCambiarEstadoAltaMedica(row, 'Rechazado')}>
                  <Translate value="rechazar" />
                </Button>
              )}
              {(['Recibido'].includes(row.altaMedica.estado)) && (
                <Button
                  className={editable ? classes.btnWarn : classes.btnGrey}
                  disabled={!editable}
                  onClick={() => handleMasInfoAltaMedica(row)}>
                  <Translate value="solicitarInfo" />
                </Button>
              )}
              {(['Recibido', 'Observado'].includes(row.altaMedica.estado)) && (
                <Button
                  className={editable ? classes.btnSave : classes.btnGrey}
                  disabled={!editable}
                  onClick={() => handleCambiarEstadoAltaMedica(row, 'Aprobado')}>
                  <Translate value="aprobar" />
                </Button>
              )}
              {(['Aprobado'].includes(row.altaMedica.estado)) && (
                <Button
                  className={editable ? classes.btnCancel : classes.btnGrey}
                  disabled={!editable}
                  onClick={() => handleCambiarEstadoAltaMedica(row, 'Rechazado')}>
                  <Translate value="rechazar" />
                </Button>
              )}
            </div>}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      position: 'relative',
    },
    container: {
      // maxHeight: 440,
    },
    btnAction: {
      margin: '0 3px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    box: {
      background: '#FFFFFF',
      border: '1px solid #FAE268',
      boxSizing: 'border-box',
      borderRadius: '6px',
      marginTop: '15px',
      display: 'flex',
      margin: theme.spacing(0, 1, 1, 1),
      borderRight: "1px solid #AAAAAA",
    },
    boxItem: {
      padding: '17px',
      background: 'transparent',
      border: 'none',
      borderBottom: "1px solid #AAAAAA",
      borderLeft: "1px solid #AAAAAA",
      width: "220px",
      textAlign: 'center',
      cursor: 'pointer',
    },
    boxItemSelected: {
      fontWeight: 'bold',
      backgroundColor: '#fff7b080',
    },
    boxItemEnd: {
      padding: '17px',
      background: 'transparent',
      border: 'none',
      width: '100%',
      textAlign: 'left'
    },
    boxTitle: {
      fontSize: '1rem',
      margin: '10px 0',
    },
    estadoRecibido: {
      // background: '#F7CBC4'
    },
    rowItem: {
      cursor: 'pointer',
    },
    detalle: {
      margin: '0 0 4px',
      fontSize: '1rem'
    },
    descripcion: {
      color: '#767676',
      // paddingLeft: '10px'
    },
    titulo: {
      fontSize: '1.1rem',
      margin: '0 0 4px',
      color: '#050505'
    },
    adjuntos: {
      listStyle: 'none',
      paddingLeft: '10px'
    },
    link: {
      color: '#323232',
      display: 'inline-block',
      padding: '0 10px 0',
      textDecoration: 'underline'
    },
    btnSave: {
      padding: theme.spacing(1, 4),
      marginLeft: '5px',
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnSendMail: {
      padding: theme.spacing(1, 4),
      marginLeft: '5px',
      backgroundColor: theme.palette.grey[300],
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnCancel: {
      padding: theme.spacing(1, 4),
      marginLeft: '5px',
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    btnWarn: {
      padding: theme.spacing(1, 4),
      marginLeft: '5px',
      backgroundColor: theme.palette.warning.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.warning.light,
      },
    },
    btnPrimary: {
      padding: theme.spacing(1, 4),
      marginLeft: '5px',
      backgroundColor: theme.palette.primary.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    btnGrey: {
      padding: theme.spacing(1, 4),
      marginLeft: '5px',
      backgroundColor: theme.palette.grey[300],
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
    },
    btnContainer: {
      textAlign: 'right'
    },
    tablaDetalle: {
      '& td, th': {
        verticalAlign: 'top',
      }
    },
    filterItem: {
      minWidth: '190px',
      margin: '0 16px',
    },
  })
);

export default Permisos;
