import { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  makeStyles,
  Theme,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@material-ui/core';
import { Editor, RawDraftContentState } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import http from 'repositories/http';
import { Control, Controller, useWatch } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

const DEFAULT_DELAY = 500;

type Props = {
  name: string;
  label?: string;
  onChange?: (newValue: string) => void;
  setImagesDelete?: (images: string[]) => void;
  imagesDelete?: string[];
  delay?: number;
  error?: string;
  placeholder?: string;
  control: Control<any>;
};

export const FormInputEditorHTML = ({
  name,
  label,
  onChange,
  setImagesDelete,
  imagesDelete,
  delay,
  control,
  error,
  placeholder,
}: Props): ReactElement => {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const DELAY = delay || DEFAULT_DELAY;

  const [namesImageUpload, setNamesImageUpload] = useState<string[]>([]);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);

  const [componenteInicializado, setComponenteInicializado] = useState(false);

  const debouncedOnChange = useDebouncedCallback((content: string) => {
    if (onChange) onChange(content);
  }, DELAY);

  const handleEditorStateChange = useCallback(
    (editorState: EditorState, onChange: (content: string) => void) => {
      setEditorState(editorState);
      const content = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      );
      onChange(content);
      debouncedOnChange.callback(content);
    },
    [debouncedOnChange],
  );

  async function uploadFile(fileItem: File): Promise<any> {
    try {
      const { name } = fileItem;
      const FILE = fileItem;
      const FILENAME = `${Date.now()}-${name}`;

      // Validar que el nombre del archivo tiene extensión
      const tieneExtension = FILENAME.indexOf('.') >= 0;
      if (!tieneExtension) {
        return {
          error: `El nombre del archivo es incorrecto (No tiene extensión): "${FILENAME}"`,
        };
      }

      // Subir el archivo al servidor
      const uploadResponse = await http.uploadFile(FILE, FILENAME);

      // Verificar si hubo un error en la respuesta
      if (uploadResponse.error) {
        return { error: uploadResponse.error };
      }

      // Devolver la URL de la imagen subida en el formato esperado
      setNamesImageUpload([...namesImageUpload, FILENAME]);
      return {
        data: {
          link: encodeURI(uploadResponse.file),
        },
      };
    } catch (error) {
      console.error('Error al subir el archivo:', error);
      return { error: 'Error al subir el archivo' };
    }
  }

  const onContentStateChange = (contentState: RawDraftContentState) => {
    if (!contentState.blocks) return;

    const normalizeImageName = (name: string) => name.replace(/%20/g, ' ');

    const images = contentState.blocks.filter(
      (block) => block.type === 'atomic',
    );

    // Obtener nombres actuales de imágenes
    const currentImageNames = images
      .map((image) => {
        const imageEntity = image.entityRanges[0];
        if (!imageEntity) return null;
        const imageSrc = contentState.entityMap[imageEntity.key].data.src;
        return normalizeImageName(imageSrc.split('/').pop()); // Normalizamos el nombre
      })
      .filter(Boolean) as string[];

    // Si no hay imágenes cargadas y no hay nuevas imágenes en el contenido
    if (namesImageUpload.length === 0 && currentImageNames.length === 0) {
      setNamesImageUpload(Array.from(new Set(currentImageNames)));
      const deleteImages = imagesToDelete.filter(
        (name) => !currentImageNames.includes(name),
      );
      const combinedToDelete = Array.from(
        new Set([...deleteImages, ...(imagesDelete || [])]),
      );
      setImagesToDelete(combinedToDelete);
      if (setImagesDelete) setImagesDelete(combinedToDelete);
      return;
    }

    // Detectar imágenes nuevas y eliminadas
    const newImages = currentImageNames.filter(
      (imageName) => !namesImageUpload.includes(imageName),
    );
    const deleteImages = namesImageUpload.filter(
      (name) => !currentImageNames.includes(name),
    );

    // Actualizar imágenes subidas
    if (newImages.length > 0) {
      const updatedNames = Array.from(
        new Set([...namesImageUpload, ...newImages]),
      );
      setNamesImageUpload(updatedNames);
      const updatedDeleteImages = imagesToDelete.filter(
        (name) => !newImages.includes(name),
      );
      setImagesToDelete(Array.from(new Set(updatedDeleteImages)));
      if (setImagesDelete)
        setImagesDelete(Array.from(new Set(updatedDeleteImages)));
    }

    // Detectar y eliminar imágenes que ya no están en el contenido
    if (deleteImages.length > 0) {
      const updatedToDelete = Array.from(
        new Set([...imagesToDelete, ...deleteImages]),
      );
      setImagesToDelete(updatedToDelete);
      if (setImagesDelete) setImagesDelete(updatedToDelete);
      setNamesImageUpload((prevNames) =>
        prevNames.filter((name) => !deleteImages.includes(name)),
      );
    }
  };

  const watchedValue = useWatch({
    control,
    name,
  });

  useEffect(() => {
    if (
      watchedValue &&
      !editorState.getCurrentContent().hasText() &&
      !componenteInicializado
    ) {
      console.log('watchedValue', watchedValue);
      const contentBlock = htmlToDraft(watchedValue);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      setEditorState(EditorState.createWithContent(contentState));
      setComponenteInicializado(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValue]);

  return (
    <FormControl className={classes.formControl} error={!!error}>
      <div className={classes.formContainer}>
        {label && (
          <FormLabel component="legend" className={classes.formLabel}>
            {label}
          </FormLabel>
        )}
        <Controller
          name={name}
          control={control}
          defaultValue={draftToHtml(
            convertToRaw(editorState.getCurrentContent()),
          )}
          render={({ field: { onChange } }) => (
            <Editor
              key={name}
              placeholder={placeholder || ''}
              editorState={editorState}
              onEditorStateChange={(newState) =>
                handleEditorStateChange(newState, onChange)
              }
              wrapperClassName="wrapperClass"
              editorClassName="editorClass"
              toolbarClassName="toolbarClass"
              localization={{
                locale: localStorage.getItem('locale') || 'es',
              }}
              onContentStateChange={onContentStateChange}
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'fontSize',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'emoji',
                  'image',
                  'history',
                ],
                inline: {
                  inDropdown: false,
                  options: ['bold', 'italic', 'underline', 'strikethrough'],
                },
                list: {
                  inDropdown: false,
                  options: ['unordered', 'ordered'],
                },
                image: {
                  uploadEnabled: true,
                  uploadCallback: uploadFile,
                  previewImage: true,
                  defaultSize: {
                    height: 'auto',
                    width: 'auto',
                  },
                  alt: { present: true, mandatory: false },
                },
              }}
            />
          )}
        />
      </div>
      <FormHelperText className={classes.helperText}>{error}</FormHelperText>
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    paddingTop: theme.spacing(0),
  },
  formContainer: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    minHeight: theme.spacing(25),
    background: theme.palette.background.paper,
  },
  formLabel: {
    padding: theme.spacing(1, 1, 1, 1.5),
  },
  helperText: {
    paddingLeft: theme.spacing(1.5),
  },
}));
