import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  ListSubheader,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Autocomplete, AutocompleteRenderGroupParams } from '@material-ui/lab';
import { ICertificateUploadForm } from 'models/certificate_model';
import message from 'plugins/utils/message';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { obtenerUsuariosParaCertificadoService } from 'repositories/certificate_repository';
import { downloadCertificate } from 'store/actions/certificado';
import { SelectOption } from 'views/Ticket/components/form-ticket';
import { FormInputDropzone } from 'views/Ticket/components/FormInputDropzone';

type FormUploadCertificateProps = {
  datosEditar?: {
    id: string;
    esLinkExterno: boolean;
    linkExterno: string;
    adjunto: string;
    nombreCompleto: string;
  };
  loadingModal: boolean;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: ICertificateUploadForm) => void;
};

const FormUploadCertificate: React.FC<FormUploadCertificateProps> = ({
  datosEditar,
  loadingModal,
  isModalOpen,
  handleClose,
  handleSave,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { handleSubmit, control, getValues, setValue, watch, formState } =
    useForm<ICertificateUploadForm>({
      defaultValues: {
        id: datosEditar?.id || null,
        esProfesor: false,
        usuario: '',
        urlArchivo: datosEditar?.esLinkExterno
          ? datosEditar?.linkExterno || ''
          : '',
        adjunto: null,
      },
      mode: 'all',
    });

  const onSubmit = (data: ICertificateUploadForm) => {
    if (!data.id && !data.usuario) {
      message.error(I18n.t('debeSeleccionarUsuario'));
      return;
    }

    if (!data.adjunto && !data.urlArchivo) {
      message.error(I18n.t('debeAdjuntarArchivoOIngresarURL'));
      return;
    }

    handleSave(data);
  };

  const [loading, setLoading] = React.useState<boolean>(false);
  const [usuarios, setUsuarios] = React.useState<
    {
      value: string;
      label: string;
      caption: string;
    }[]
  >([]);

  const cargarUsuarios = async (tipo: 'ESTUDIANTE' | 'PROFESOR') => {
    setLoading(true);
    const usuarios = await obtenerUsuariosParaCertificadoService(tipo);
    const usuariosOptions = usuarios?.map((item) => ({
      value: item.id || '',
      label: `${item.nombre} ${
        item.codigoCurso ? '- ' + item.codigoCurso : ''
      }`,
      caption: item.id || '',
    }));
    setUsuarios(usuariosOptions);
    setLoading(false);
  };

  useEffect(() => {
    if (datosEditar) return;

    if (getValues('usuario')) {
      setValue('usuario', null);
    }
    cargarUsuarios(getValues('esProfesor') ? 'PROFESOR' : 'ESTUDIANTE');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues('esProfesor')]);

  const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader key={params.key} component="div">
      {params.group}
    </ListSubheader>,
    params.children,
  ];

  const renderAdjuntosExistentes = () => {
    const downloadFile = async (idCertificado: string) => {
      if (!datosEditar) return;
      setLoading(true);
      if (datosEditar.esLinkExterno && datosEditar.linkExterno) {
        // abrimos en una nueva ventana el archivo
        window.open(datosEditar.linkExterno, '_blank');
        setLoading(false);
        return;
      }
      await dispatch(downloadCertificate(idCertificado, datosEditar.adjunto));
      setLoading(false);
    };
    return (
      <>
        {datosEditar?.adjunto && !datosEditar.esLinkExterno && (
          <Grid item xs={12} md={12}>
            <Typography>Archivo subido:</Typography>
            <ul className={classes.adjuntos}>
              <li>
                <Box
                  style={{
                    cursor: loading ? 'none' : 'pointer',
                  }}
                  onClick={() => {
                    if (loading) return;
                    downloadFile(datosEditar?.id || '');
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.3 0C17.686 0 19.96 2.371 19.96 5.899V14.05C19.96 17.578 17.686 19.949 14.3 19.949H10.98C10.566 19.949 10.23 19.613 10.23 19.199C10.23 18.785 10.566 18.449 10.98 18.449H14.3C16.827 18.449 18.46 16.722 18.46 14.05V5.899C18.46 3.226 16.827 1.5 14.3 1.5H5.65C3.129 1.5 1.5 3.226 1.5 5.899V14.05C1.5 16.722 3.129 18.449 5.65 18.449H6.371C6.785 18.449 7.121 18.785 7.121 19.199C7.121 19.613 6.785 19.949 6.371 19.949H5.65C2.271 19.949 0 17.578 0 14.05V5.899C0 2.371 2.271 0 5.65 0H14.3ZM13.2915 9.5434C13.9825 9.5224 14.6585 9.7854 15.1405 10.2824L17.2175 12.4234C17.5055 12.7214 17.4985 13.1964 17.2005 13.4844C16.9035 13.7734 16.4285 13.7644 16.1405 13.4684L14.0635 11.3274C13.8755 11.1334 13.6295 11.0244 13.3525 11.0424C13.0825 11.0534 12.8395 11.1754 12.6675 11.3844L10.4385 14.0964C10.0455 14.5744 9.4655 14.8644 8.8475 14.8914C8.2285 14.9044 7.6265 14.6804 7.1925 14.2394L6.3065 13.3354C6.1765 13.2014 6.0115 13.0834 5.8275 13.1294C5.6465 13.1304 5.4795 13.2034 5.3555 13.3334L3.8255 14.9484C3.6785 15.1044 3.4805 15.1824 3.2815 15.1824C3.0965 15.1824 2.9105 15.1144 2.7655 14.9774C2.4655 14.6924 2.4525 14.2174 2.7375 13.9164L4.2665 12.3024C4.6745 11.8704 5.2265 11.6314 5.8215 11.6294C6.3955 11.6674 6.9705 11.8624 7.3825 12.2904L8.2635 13.1884C8.4005 13.3284 8.5805 13.4064 8.7815 13.3924C8.9775 13.3834 9.1545 13.2954 9.2795 13.1434L11.5085 10.4314C11.9485 9.8964 12.5985 9.5724 13.2915 9.5434ZM6.5603 4.6296C7.9403 4.6296 9.0633 5.7526 9.0633 7.1336C9.0633 8.5146 7.9403 9.6376 6.5603 9.6376C5.1793 9.6376 4.0563 8.5146 4.0563 7.1336C4.0563 5.7526 5.1793 4.6296 6.5603 4.6296ZM6.5603 6.1296C6.0063 6.1296 5.5563 6.5796 5.5563 7.1336C5.5563 7.6876 6.0063 8.1376 6.5603 8.1376C7.1133 8.1376 7.5633 7.6876 7.5633 7.1336C7.5633 6.5796 7.1133 6.1296 6.5603 6.1296Z"
                      fill="#323232"
                    />
                  </svg>
                  <span className={classes.link}>
                    {datosEditar?.adjunto.length > 30
                      ? `${datosEditar?.adjunto.substring(
                          0,
                          10,
                        )}...${datosEditar?.adjunto.substring(
                          datosEditar?.adjunto.length - 25,
                        )}`
                      : datosEditar?.adjunto}
                  </span>
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.8611 9.14147C16.666 8.90748 16.295 8.7616 15.8915 8.7616H9.60854V0.734294C9.60854 0.328964 9.11193 0 8.50004 0C7.88815 0 7.39154 0.328964 7.39154 0.734294V8.7616L1.10858 8.7616C0.703606 8.7616 0.332628 8.90748 0.139011 9.14147C-0.0575638 9.37547 -0.0442619 9.66135 0.170048 9.88752L7.56151 17.6573C7.76548 17.8708 8.11872 18 8.50004 18C8.88136 18 9.2346 17.8708 9.43857 17.6573L16.83 9.88752C16.9424 9.76807 17 9.63198 17 9.49589C17 9.37449 16.9527 9.25211 16.8611 9.14147Z"
                      fill="#323232"
                    />
                  </svg>
                </Box>
              </li>
            </ul>
          </Grid>
        )}
      </>
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      maxWidth={'lg'}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container justify="space-between">
          {datosEditar
            ? I18n.t('editarCertificado')
            : I18n.t('certificadoExterno')}
          <IconButton
            disabled={loadingModal}
            size="small"
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Box mb={3}>
            <Grid container spacing={2}>
              <Typography
                gutterBottom
                variant="h6"
                style={{
                  fontWeight: 'bold',
                  marginBottom: 10,
                }}
              >
                <Translate value="datosDestinatario" />
              </Typography>
              {!datosEditar ? (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      <Translate value="textoAyudaCertificadoExistenteDestinatario" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="esProfesor"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          disabled={loading || loadingModal}
                          control={
                            <Checkbox
                              color="primary"
                              {...field}
                              checked={field.value}
                            />
                          }
                          label={<Translate value="esProfesor" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="usuario"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          variant="filled"
                          required
                          disabled={usuarios.length === 0}
                          className={classes.formControl}
                        >
                          <Autocomplete
                            options={usuarios}
                            autoHighlight
                            renderGroup={renderGroup}
                            getOptionLabel={(option: SelectOption) =>
                              option.label
                            }
                            renderOption={(option: SelectOption, state) => (
                              <Box {...state}>
                                <Typography variant="body2">
                                  {option.label}
                                </Typography>
                              </Box>
                            )}
                            value={
                              usuarios.find(
                                (option) => option.value === field.value,
                              ) || null
                            }
                            onChange={(
                              _event,
                              newValue: SelectOption | null,
                            ) => {
                              field.onChange(newValue ? newValue.value : '');
                            }}
                            noOptionsText={`${I18n.t('reporte.sinOpciones')}`}
                            disabled={
                              usuarios.length === 0 || loading || loadingModal
                            }
                            loading={loading}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={I18n.t(
                                  watch('esProfesor')
                                    ? 'buscarProfesor'
                                    : 'buscarEstudiante',
                                )}
                                variant="filled"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      )}
                      rules={{
                        required: 'Is required',
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={12}>
                    <Typography gutterBottom variant="body1">
                      - {datosEditar?.nombreCompleto}
                    </Typography>
                  </Grid>
                </>
              )}
              <Divider className={classes.divider} />
              <Grid item xs={12}>
                <Typography variant="h6">
                  <Translate value="agregarAdjunto" />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <Translate value="textoAyudaAdjunto" />
                </Typography>
              </Grid>

              {renderAdjuntosExistentes()}
              <Grid item xs={12}>
                <Controller
                  name="adjunto"
                  control={control}
                  rules={{
                    required: 'Is required',
                  }}
                  render={({ field, fieldState }) => (
                    <FormInputDropzone
                      label={I18n.t('form.subirArchivos')}
                      name={field.name}
                      value={field.value}
                      filesLimit={1}
                      onChange={field.onChange}
                      disabled={loading || loadingModal}
                      acceptedFiles={['application/pdf']}
                      error={!!fieldState.error}
                      maxFileSize={10 * 1024 * 1024}
                      dropzoneText={I18n.t('form.dropzoneTextFiles')}
                      helperText={
                        fieldState.error
                          ? 'Please upload valid files'
                          : undefined
                      }
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Controller
                  name="urlArchivo"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField {...field} label='URL' fullWidth variant="filled" />
                  )}
                />
              </Grid> */}
            </Grid>
          </Box>
        </DialogContent>

        {(loading || loadingModal) && (
          <Box left={0} right={0}>
            <LinearProgress color="primary" />
          </Box>
        )}

        <DialogActions
          style={{
            margin: 10,
          }}
        >
          <Button
            disabled={loadingModal || loading}
            onClick={handleClose}
            className={classes.btnError}
            fullWidth
          >
            <Translate value="form.cancelar" />
          </Button>
          <Button
            disabled={!formState.isValid || loadingModal || loading}
            type="submit"
            className={classes.btnSave}
            fullWidth
          >
            {datosEditar ? (
              <Translate value="guardar" />
            ) : (
              <Translate value="enviar" />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      borderRadius: '16px',
      '&::-webkit-scrollbar': {
        width: '5px',
      },
    },
    divider: {
      background: theme.palette.secondary.main,
      width: '100%',
      height: '1px',
      marginTop: '10px',
    },
    formControl: {
      width: '100%',
    },
    loader: {
      position: 'absolute',
      left: 0,
      right: 0,
      height: '4px',
      background: '#e0e0e0',
      zIndex: 9,
    },
    btnError: {
      maxWidth: '160px',
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.light,
      },
    },
    btnSave: {
      maxWidth: '160px',
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.light,
      },
    },
    adjuntos: {
      listStyle: 'none',
      paddingLeft: '10px',
    },
    link: {
      color: '#323232',
      display: 'inline-block',
      padding: '0 10px 0',
      textDecoration: 'underline',
    },
  }),
);

export default FormUploadCertificate;
