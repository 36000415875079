import React, { useEffect } from 'react';
import {
  Box,
  Button,
  createStyles,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import MainLayout from 'components/layout/MainLayout';
import clsx from 'clsx';
import { I18n, Translate } from 'react-redux-i18n';
import { Add, FilterList, Refresh, Search } from '@material-ui/icons';
import { MultiSelectFilter } from 'views/Report/components/filters/MultiSelectFilter';
import {
  ICertificate,
  ICertificateEdit,
  ICertificateForm,
  ICertificateUploadForm,
} from 'models/certificate_model';
import FormCreateCertificate from './components/FormCertificate/create';
import { useDispatch } from 'react-redux';
import {
  addCertificates,
  cargarCertificados,
  cargarPlantillasCertificados,
  deleteCertificate,
  downloadCertificate,
  editCertificate,
  uploadCertificate,
} from 'store/actions/certificado';
import { RootState, useStateSelector } from 'store/reducers';
import message from 'plugins/utils/message';
import moment from 'moment';
import FormUploadCertificate from './components/FormUploadCertificate';
import FormEditCertificate from './components/FormCertificate/edit';
import { useIsMounted } from 'hooks/useIsMounted';

export enum TipoCertificadoGenerado {
  GENERADO_SISTEMA = 'GENERADO_SISTEMA',
  EXTERNO = 'EXTERNO',
}

interface Column {
  id:
    | 'actions'
    | 'tipo'
    | 'primerApellido'
    | 'segundoApellido'
    | 'nombre'
    | 'fechaEmision'
    | 'certificado';
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
}

const columnsTable: Column[] = [
  {
    id: 'actions',
    label: I18n.t('tabla.acciones'),
  },
  {
    id: 'tipo',
    label: I18n.t('tipoCertificado'),
  },
  {
    id: 'primerApellido',
    label: I18n.t('tabla.primerApellido'),
  },
  {
    id: 'segundoApellido',
    label: I18n.t('tabla.segundoApellido'),
  },
  {
    id: 'nombre',
    label: I18n.t('tabla.nombre'),
  },

  {
    id: 'fechaEmision',
    label: I18n.t('tabla.fecha'),
  },

  {
    id: 'certificado',
    label: I18n.t('certificado'),
  },
];

// SE OBTIENE EL AÑO ACTUAL Y LOS AÑOS ANTERIORES HASTA 2023
const getYears = () => {
  const currentYear = moment().year();
  const years = [];
  for (let i = 2023; i <= currentYear; i++) {
    years.push(i.toString());
  }
  return years;
};

const Certificates: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { locale } = useStateSelector((state: RootState) => state.i18n);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const dispatch = useDispatch();
  const {
    plantillas: tipoCertificados,
    certificates,
    countCertificates,
  } = useStateSelector((state: RootState) => state.certificateState);

  const [estadoActual, setEstadoActual] =
    React.useState<TipoCertificadoGenerado>(
      TipoCertificadoGenerado.GENERADO_SISTEMA,
    );

  const [certificadoSeleccionado, setCertificadoSeleccionado] =
    React.useState<ICertificate | null>(null);

  const [modalCreateCertificate, setModalCreateCertificate] =
    React.useState<boolean>(false);
  const [modalEditCertificate, setModalEditCertificate] =
    React.useState<boolean>(false);

  const [modalUploadCertificate, setModalUploadCertificate] =
    React.useState<boolean>(false);

  const [loadingModal, setLoadingModal] = React.useState<boolean>(false);

  const [filterOpen, setFilterOpen] = React.useState<boolean>(false);

  const [columns, setColumns] = React.useState<Column[]>(columnsTable);

  const [filterCertificado, setFilterCertificado] = React.useState<
    string | null
  >(null);
  const [filterGestion, setFilterGestion] = React.useState<string | null>(null);

  const [gestiones] = React.useState(getYears);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleGetCertificados = (tipo: TipoCertificadoGenerado) => {
    setEstadoActual(tipo);
    setFilterCertificado(null);
    setFilterGestion(null);
    setLoading(true);
    const newPage = 0;
    setPage(newPage);
    dispatch(cargarCertificados(tipo, rowsPerPage, newPage));
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleClickOpen = () => {
    if (estadoActual === TipoCertificadoGenerado.GENERADO_SISTEMA) {
      setModalCreateCertificate(true);
    }
    if (estadoActual === TipoCertificadoGenerado.EXTERNO) {
      setModalUploadCertificate(true);
    }
  };

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const isMounted = useIsMounted();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    obtenerCertificados({
      _limit: rowsPerPage,
      _page: newPage,
      tipo: filterCertificado,
      gestion: filterGestion,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    const newPage = 0;
    setPage(newPage);
    obtenerCertificados({
      _limit: +event.target.value,
      _page: newPage,
      tipo: filterCertificado,
      gestion: filterGestion,
    });
  };

  const handleSubmitFilter = () => {
    const filters = {
      tipo: filterCertificado,
      gestion: filterGestion,
      _limit: rowsPerPage,
    };
    setPage(0);
    obtenerCertificados(filters);
  };

  const handleFilterCancel = () => {
    setFilterCertificado(null);
    setFilterGestion(null);
    setPage(0);
    obtenerCertificados({
      _limit: rowsPerPage,
      _page: 0,
      tipo: null,
      gestion: null,
    });
  };

  const obtenerCertificados = async (filters: {
    _limit?: number;
    _page?: number;
    tipo: string | null;
    gestion: string | null;
    nombreUsuario?: string;
  }) => {
    setLoading(true);
    await dispatch(
      cargarCertificados(
        estadoActual || TipoCertificadoGenerado.GENERADO_SISTEMA,
        filters._limit,
        filters._page,
        filters.nombreUsuario,
        filters?.gestion || undefined,
        filters?.tipo || undefined,
      ),
    );
    if (isMounted()) setLoading(false);
  };

  const handleRefreshData = () => {
    obtenerCertificados({
      tipo: filterCertificado,
      gestion: filterGestion,
    });
  };

  const handleClose = () => {
    setModalCreateCertificate(false);
    setModalUploadCertificate(false);

    setCertificadoSeleccionado(null);
    setModalEditCertificate(false);
  };

  const handleCreateUpdateUploadCertificate = async (
    data: ICertificateUploadForm,
  ) => {
    if (!data.adjunto || data.adjunto === '' || data.adjunto === '[]') {
      message.error(
        locale === 'es'
          ? 'No se ha subido ningún archivo'
          : 'Es wurde keine Datei hochgeladen',
      );
      return;
    }

    let messageAlert =
      locale === 'es'
        ? `Se van a generar un total de ${1} certificados en fecha ${moment().format(
            'DD/MM/YYYY',
          )} ¿Confirmar el envío?`
        : `Es werden insgesamt ${1} Zertifikate am ${moment()
            .locale('de')
            .format('DD/MM/YYYY')} generiert. Versand bestätigen?`;

    if (data.id) {
      messageAlert =
        locale === 'es'
          ? `Se va a modificar la información del certificado que ya estaba generado. ¿Confirmar el envío?`
          : 'Die Informationen des bereits erstellten Zertifikats werden geändert. Versand bestätigen?';
    }

    const send = await message.confirm(messageAlert, true);

    if (!send) return;
    setLoadingModal(true);

    if (data.id) {
      const result = await dispatch(
        editCertificate({
          id: data.id,
          tipoGenerado: TipoCertificadoGenerado.EXTERNO,
          urlArchivo: data.urlArchivo,
          adjunto: data.adjunto,
          tipoCertificado: null,
          frase: null,
          fechaCertificado: null,
          nombreCompleto: data.usuario || '',
          fraseInicial: null,
          cantidadCaracteres: null,
        }),
      );
      setLoadingModal(false);

      if (!result) {
        return;
      }
      message.success(`${I18n.t('actualizandoCertificado')}`);
    } else {
      const result = await dispatch(uploadCertificate(data));
      setLoadingModal(false);

      if (!result) {
        return;
      }
      message.success(`${1} ${I18n.t('creandoCertificados')}`);
    }

    setTimeout(() => {
      obtenerCertificados({
        tipo: filterCertificado,
        gestion: filterGestion,
        _limit: rowsPerPage,
        _page: page,
      });
    }, 2000);

    handleClose();
  };

  const handleSaveCertificate = async (
    data: ICertificateForm,
    totalCertificados: number,
  ) => {
    const msj = `Se van a generar un total de ${totalCertificados} certificados en fecha ${moment().format(
      'DD/MM/YYYY',
    )} ¿Confirmar el envío?`;

    const msjAleman = `Es werden insgesamt ${totalCertificados} Zertifikate am ${moment()
      .locale('de')
      .format('DD/MM/YYYY')} generiert. Versand bestätigen?`;

    const send = await message.confirm(locale === 'es' ? msj : msjAleman, true);

    if (!send) return;
    setLoadingModal(true);
    const result = await dispatch(addCertificates(data));
    setLoadingModal(false);

    if (!result) {
      return;
    }
    message.success(`${totalCertificados} ${I18n.t('creandoCertificados')}`);

    setTimeout(() => {
      obtenerCertificados({
        tipo: filterCertificado,
        gestion: filterGestion,
        _limit: rowsPerPage,
        _page: page,
      });
    }, 2000);

    handleClose();
  };

  const handleEditCertificate = async (data: ICertificateEdit) => {
    const send = await message.confirm(
      `Se va a modificar la información del certificado que ya estaba generado. ¿Confirmar el envío?`,
      true,
    );

    if (!send) return;
    setLoadingModal(true);
    const result = await dispatch(editCertificate(data));
    setLoadingModal(false);

    if (!result) {
      return;
    }
    message.success(`${I18n.t('editandoCertificado')}`);

    setTimeout(() => {
      obtenerCertificados({
        tipo: filterCertificado,
        gestion: filterGestion,
        _limit: rowsPerPage,
        _page: page,
      });
    }, 2000);

    handleClose();
  };

  const cargarDatosIniciales = async () => {
    setLoading(true);
    dispatch(cargarPlantillasCertificados());
    dispatch(cargarCertificados(estadoActual, rowsPerPage, page));
    setLoading(false);
  };

  const handleModalCertificate = (row: ICertificate) => {
    setModalEditCertificate(true);
    setCertificadoSeleccionado(row);
  };

  const handleDeleteCertificate = async (id: string) => {
    const msj =
      locale === 'es'
        ? 'Se va a eliminar la información del certificado que ya estaba generado. ¿Confirmar la eliminación?'
        : 'Die Informationen des bereits erstellten Zertifikats werden gelöscht. Löschung bestätigen?';
    const send = await message.confirm(msj);
    if (!send) return;
    setLoading(true);
    await dispatch(deleteCertificate(id));
    setTimeout(() => {
      obtenerCertificados({
        tipo: filterCertificado,
        gestion: filterGestion,
        _limit: rowsPerPage,
        _page: page,
      });
    }, 1000);
    setLoading(false);
  };

  useEffect(() => {
    cargarDatosIniciales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setColumns([
      {
        id: 'actions',
        label: I18n.t('tabla.acciones'),
      },
      {
        id: 'tipo',
        label: I18n.t('tipoCertificado'),
      },
      {
        id: 'primerApellido',
        label: I18n.t('tabla.primerApellido'),
      },
      {
        id: 'segundoApellido',
        label: I18n.t('tabla.segundoApellido'),
      },
      {
        id: 'nombre',
        label: I18n.t('tabla.nombre'),
      },

      {
        id: 'fechaEmision',
        label: I18n.t('tabla.fecha'),
      },

      {
        id: 'certificado',
        label: I18n.t('certificado'),
      },
    ]);
    dispatch(cargarPlantillasCertificados());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const renderColumnActions = (row: ICertificate) => {
    return (
      <Box display="flex">
        <Tooltip title="Editar certificate">
          <Box>
            <IconButton
              disabled={loading || loadingModal}
              size="small"
              onClick={() => handleModalCertificate(row)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.9898 18.9533C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533H19.9898ZM16.0299 3.69906L17.5049 4.87078C18.1097 5.34377 18.513 5.96726 18.6509 6.62299C18.8101 7.3443 18.6403 8.0527 18.1628 8.66544L9.3764 20.0279C8.97316 20.5439 8.37891 20.8341 7.74222 20.8449L4.24039 20.8879C4.04939 20.8879 3.89021 20.7589 3.84777 20.5761L3.0519 17.1255C2.91395 16.4912 3.0519 15.8355 3.45514 15.3303L9.68413 7.26797C9.79025 7.13898 9.98126 7.11855 10.1086 7.21422L12.7297 9.29967C12.8994 9.43942 13.1329 9.51467 13.377 9.48242C13.8969 9.41792 14.2471 8.94493 14.1941 8.43969C14.1622 8.1817 14.0349 7.96671 13.8651 7.80546C13.812 7.76246 11.3183 5.76301 11.3183 5.76301C11.1591 5.63401 11.1273 5.39752 11.2546 5.23735L12.2415 3.95706C13.1541 2.78534 14.7459 2.67784 16.0299 3.69906Z"
                  fill={theme.palette.warning.main}
                />
              </svg>
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip title="Eliminar certificate">
          <Box>
            <IconButton
              size="small"
              disabled={loading || loadingModal}
              onClick={() => (row.id ? handleDeleteCertificate(row.id) : null)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.9391 8.69713C19.1384 8.69713 19.3193 8.78413 19.4623 8.93113C19.5955 9.08813 19.6626 9.28313 19.6432 9.48913C19.6432 9.55712 19.1102 16.2971 18.8058 19.134C18.6152 20.875 17.4929 21.932 15.8094 21.961C14.5149 21.99 13.2496 22 12.0038 22C10.6811 22 9.38763 21.99 8.13206 21.961C6.50498 21.922 5.38168 20.846 5.20079 19.134C4.88763 16.2871 4.36439 9.55712 4.35467 9.48913C4.34494 9.28313 4.41108 9.08813 4.54529 8.93113C4.67756 8.78413 4.86818 8.69713 5.06852 8.69713H18.9391ZM14.0647 2C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647Z"
                  fill={theme.palette.error.main}
                />
              </svg>
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    );
  };

  const renderAdjuntos = (
    adjunto: string,
    esLinkExterno: boolean,
    linkExterno: string | null,
    idCertificado: string,
  ) => {
    const downloadFile = async (idCertificado: string) => {
      setLoading(true);
      if (esLinkExterno && linkExterno) {
        // abrimos en una nueva ventana el archivo
        window.open(linkExterno, '_blank');
        setLoading(false);
        return;
      }
      await dispatch(downloadCertificate(idCertificado, adjunto));
      setLoading(false);
    };

    return (
      <ul className={classes.adjuntos}>
        {String(adjunto)
          .split(',')
          .map((item, itemIndex) => (
            <li key={itemIndex}>
              <Box
                style={{
                  cursor: loading ? 'none' : 'pointer',
                }}
                onClick={() => {
                  if (loading) return;
                  downloadFile(idCertificado);
                }}
              >
                {item.indexOf('.pdf') !== -1 && (
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8534 0C16.0668 0 17.9913 1.95143 18 5.20971V14.7903C18 18.0486 16.0842 20 12.8621 20H10.0058C9.6395 19.959 9.36224 19.6452 9.36224 19.2715C9.36224 18.8978 9.6395 18.584 10.0058 18.543H12.8534C15.3091 18.543 16.5457 17.2804 16.5457 14.7903V5.20971C16.5457 2.71965 15.3091 1.45695 12.8534 1.45695H5.13788C2.68215 1.45695 1.43686 2.71965 1.43686 5.20971V14.7903C1.43686 17.2804 2.68215 18.543 5.13788 18.543C5.50419 18.584 5.78145 18.8978 5.78145 19.2715C5.78145 19.6452 5.50419 19.959 5.13788 20C1.92453 20 0 18.0486 0 14.7903V5.20971C0 1.9426 1.92453 0 5.13788 0H12.8534ZM12.5922 13.5099C12.9865 13.5099 13.3062 13.8341 13.3062 14.234C13.3062 14.6339 12.9865 14.9581 12.5922 14.9581H5.38171C4.98734 14.9581 4.66763 14.6339 4.66763 14.234C4.66763 13.8341 4.98734 13.5099 5.38171 13.5099H12.5922ZM12.5922 9.27152C12.8677 9.24072 13.1364 9.37244 13.2837 9.61057C13.431 9.84871 13.431 10.1513 13.2837 10.3894C13.1364 10.6276 12.8677 10.7593 12.5922 10.7285H5.38171C5.01541 10.6875 4.73814 10.3737 4.73814 10C4.73814 9.62632 5.01541 9.31248 5.38171 9.27152H12.5922ZM8.13353 5.04194C8.49983 5.0829 8.7771 5.39674 8.7771 5.77042C8.7771 6.1441 8.49983 6.45794 8.13353 6.4989H5.39042C5.02411 6.45794 4.74685 6.1441 4.74685 5.77042C4.74685 5.39674 5.02411 5.0829 5.39042 5.04194H8.13353Z"
                      fill="#200E32"
                    />
                  </svg>
                )}
                {item.indexOf('.pdf') === -1 && (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.3 0C17.686 0 19.96 2.371 19.96 5.899V14.05C19.96 17.578 17.686 19.949 14.3 19.949H10.98C10.566 19.949 10.23 19.613 10.23 19.199C10.23 18.785 10.566 18.449 10.98 18.449H14.3C16.827 18.449 18.46 16.722 18.46 14.05V5.899C18.46 3.226 16.827 1.5 14.3 1.5H5.65C3.129 1.5 1.5 3.226 1.5 5.899V14.05C1.5 16.722 3.129 18.449 5.65 18.449H6.371C6.785 18.449 7.121 18.785 7.121 19.199C7.121 19.613 6.785 19.949 6.371 19.949H5.65C2.271 19.949 0 17.578 0 14.05V5.899C0 2.371 2.271 0 5.65 0H14.3ZM13.2915 9.5434C13.9825 9.5224 14.6585 9.7854 15.1405 10.2824L17.2175 12.4234C17.5055 12.7214 17.4985 13.1964 17.2005 13.4844C16.9035 13.7734 16.4285 13.7644 16.1405 13.4684L14.0635 11.3274C13.8755 11.1334 13.6295 11.0244 13.3525 11.0424C13.0825 11.0534 12.8395 11.1754 12.6675 11.3844L10.4385 14.0964C10.0455 14.5744 9.4655 14.8644 8.8475 14.8914C8.2285 14.9044 7.6265 14.6804 7.1925 14.2394L6.3065 13.3354C6.1765 13.2014 6.0115 13.0834 5.8275 13.1294C5.6465 13.1304 5.4795 13.2034 5.3555 13.3334L3.8255 14.9484C3.6785 15.1044 3.4805 15.1824 3.2815 15.1824C3.0965 15.1824 2.9105 15.1144 2.7655 14.9774C2.4655 14.6924 2.4525 14.2174 2.7375 13.9164L4.2665 12.3024C4.6745 11.8704 5.2265 11.6314 5.8215 11.6294C6.3955 11.6674 6.9705 11.8624 7.3825 12.2904L8.2635 13.1884C8.4005 13.3284 8.5805 13.4064 8.7815 13.3924C8.9775 13.3834 9.1545 13.2954 9.2795 13.1434L11.5085 10.4314C11.9485 9.8964 12.5985 9.5724 13.2915 9.5434ZM6.5603 4.6296C7.9403 4.6296 9.0633 5.7526 9.0633 7.1336C9.0633 8.5146 7.9403 9.6376 6.5603 9.6376C5.1793 9.6376 4.0563 8.5146 4.0563 7.1336C4.0563 5.7526 5.1793 4.6296 6.5603 4.6296ZM6.5603 6.1296C6.0063 6.1296 5.5563 6.5796 5.5563 7.1336C5.5563 7.6876 6.0063 8.1376 6.5603 8.1376C7.1133 8.1376 7.5633 7.6876 7.5633 7.1336C7.5633 6.5796 7.1133 6.1296 6.5603 6.1296Z"
                      fill="#323232"
                    />
                  </svg>
                )}
                <span className={classes.link}>
                  {item.length > 30
                    ? `${item.substring(0, 10)}...${item.substring(
                        item.length - 25,
                      )}`
                    : item}
                </span>
                <svg
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.8611 9.14147C16.666 8.90748 16.295 8.7616 15.8915 8.7616H9.60854V0.734294C9.60854 0.328964 9.11193 0 8.50004 0C7.88815 0 7.39154 0.328964 7.39154 0.734294V8.7616L1.10858 8.7616C0.703606 8.7616 0.332628 8.90748 0.139011 9.14147C-0.0575638 9.37547 -0.0442619 9.66135 0.170048 9.88752L7.56151 17.6573C7.76548 17.8708 8.11872 18 8.50004 18C8.88136 18 9.2346 17.8708 9.43857 17.6573L16.83 9.88752C16.9424 9.76807 17 9.63198 17 9.49589C17 9.37449 16.9527 9.25211 16.8611 9.14147Z"
                    fill="#323232"
                  />
                </svg>
              </Box>
            </li>
          ))}
      </ul>
    );
  };

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <div className={classes.box}>
          <Box>
            <button
              type="button"
              onClick={() =>
                handleGetCertificados(TipoCertificadoGenerado.GENERADO_SISTEMA)
              }
              disabled={loading}
              className={clsx(classes.boxItem, {
                [classes.boxItemSelected]:
                  estadoActual === TipoCertificadoGenerado.GENERADO_SISTEMA,
              })}
            >
              <Translate value="certificadoNuevo" />
            </button>
          </Box>
          <Box>
            <button
              type="button"
              disabled={loading}
              onClick={() =>
                handleGetCertificados(TipoCertificadoGenerado.EXTERNO)
              }
              className={clsx(classes.boxItem, {
                [classes.boxItemSelected]:
                  estadoActual === TipoCertificadoGenerado.EXTERNO,
              })}
            >
              <Translate value="subirCertificado" />
            </button>
          </Box>
        </div>
      </Grid>

      <Paper className={classes.root}>
        {loading && (
          <Box left={0} right={0}>
            <LinearProgress color="primary" />
          </Box>
        )}
        <Box
          p={3}
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
            style={{
              gap: 10,
            }}
          >
            <Button
              variant="contained"
              disabled={loading}
              onClick={handleFilter}
            >
              <FilterList />
            </Button>
            <Box display="flex">
              <Button
                variant="contained"
                onClick={handleRefreshData}
                disabled={loading}
                startIcon={<Refresh />}
              >
                {isDesktop && <Translate value="tabla.refrescar" />}
              </Button>
            </Box>
          </Box>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#99ba82',
            }}
            disabled={loading}
            onClick={handleClickOpen}
            startIcon={<Add />}
          >
            <Translate value="form.crearCertificado" />
          </Button>
        </Box>
        {filterOpen && (
          <Box
            className={clsx(classes.filterBox, {
              [classes.filterBoxOpen]: filterOpen,
            })}
          >
            <label>
              <Translate value="tabla.buscarPor" />:{' '}
            </label>

            {estadoActual === TipoCertificadoGenerado.GENERADO_SISTEMA && (
              <Box
                className={classes.filterItem}
                style={{
                  maxWidth: 400,
                }}
              >
                <MultiSelectFilter
                  name="filter.tipo"
                  label={I18n.t('tipoCertificado')}
                  options={
                    tipoCertificados.map((item) => ({
                      label: item.nombre,
                      value: item.id,
                    })) || []
                  }
                  onChange={(_name, value) => {
                    setFilterCertificado(value as string);
                  }}
                  value={filterCertificado ? filterCertificado : ''}
                  disabled={loading}
                />
              </Box>
            )}

            <Box className={classes.filterItem}>
              <MultiSelectFilter
                name="filter.gestion"
                label={I18n.t('tabla.gestion')}
                options={
                  gestiones.map((gestion) => ({
                    id: gestion,
                    label: gestion,
                    value: gestion,
                  })) || []
                }
                onChange={(_name, value) => {
                  setFilterGestion(value as string);
                }}
                value={filterGestion ? filterGestion : ''}
                disabled={loading}
              />
            </Box>

            <Box mr={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitFilter}
                disabled={loading || (!filterCertificado && !filterGestion)}
              >
                <Search /> <Translate value="tabla.buscar" />
              </Button>
            </Box>
            {((filterCertificado &&
              Object.keys(filterCertificado).length > 0) ||
              (filterGestion && Object.keys(filterGestion).length > 0)) && (
              <Button
                variant="contained"
                onClick={handleFilterCancel}
                disabled={loading}
              >
                <Translate value="form.cancelar" />
              </Button>
            )}
          </Box>
        )}

        <React.Fragment>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: 'bold',
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {(certificates.length === 0 ||
                  certificates === undefined ||
                  certificates === null) && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Translate value="sinRegistrosCertificados" />
                    </TableCell>
                  </TableRow>
                )}
                {certificates.map((row: ICertificate) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell>{renderColumnActions(row)}</TableCell>
                      <TableCell align="left">{row.tipo}</TableCell>
                      <TableCell align="left">
                        {row.usuario.primerApellido}
                      </TableCell>
                      <TableCell align="left">
                        {row.usuario.segundoApellido}
                      </TableCell>
                      <TableCell align="left">{row.usuario.nombre}</TableCell>

                      <TableCell align="left">{row.fechaCreacion}</TableCell>
                      <TableCell align="left">
                        {renderAdjuntos(
                          row.adjunto,
                          row.tieneLinkExterno,
                          row.linkExterno,
                          row.id,
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 50, 100, 250]}
            component="div"
            count={countCertificates}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={<Translate value="tabla.filasPorPagina" />}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${I18n.t('tabla.de')} ${
                count !== -1 ? count : `${I18n.t('tabla.masDe')} ${to}`
              }`
            }
          />
        </React.Fragment>
      </Paper>

      {modalCreateCertificate &&
        estadoActual === TipoCertificadoGenerado.GENERADO_SISTEMA && (
          <FormCreateCertificate
            tiposCertificados={tipoCertificados.map((item) => ({
              caption: item.id,
              label: item.nombre,
              value: item.id,
              cantidadCaracteres: item.cantidadCaracteres,
              frase: item.fraseInicial,
            }))}
            loadingModal={loadingModal}
            isModalOpen={modalCreateCertificate}
            handleClose={handleClose}
            handleSave={handleSaveCertificate}
          />
        )}

      {(modalUploadCertificate || modalEditCertificate) &&
        estadoActual === TipoCertificadoGenerado.EXTERNO && (
          <FormUploadCertificate
            datosEditar={
              certificadoSeleccionado
                ? {
                    id: certificadoSeleccionado.id,
                    esLinkExterno: certificadoSeleccionado.tieneLinkExterno,
                    linkExterno: certificadoSeleccionado.linkExterno || '',
                    adjunto: certificadoSeleccionado.adjunto || '',
                    nombreCompleto: `${
                      certificadoSeleccionado.usuario.nombre || ''
                    } ${certificadoSeleccionado.usuario.primerApellido || ''} ${
                      certificadoSeleccionado.usuario.segundoApellido || ''
                    }`,
                  }
                : undefined
            }
            handleClose={handleClose}
            handleSave={handleCreateUpdateUploadCertificate}
            isModalOpen={modalUploadCertificate || modalEditCertificate}
            loadingModal={loadingModal}
          />
        )}

      {estadoActual === TipoCertificadoGenerado.GENERADO_SISTEMA &&
        modalEditCertificate &&
        certificadoSeleccionado && (
          <FormEditCertificate
            datosEditar={{
              id: certificadoSeleccionado.id,
              ...certificadoSeleccionado.datosCertificado,
              nombreCompleto: `${
                certificadoSeleccionado.usuario.nombre || ''
              } ${certificadoSeleccionado.usuario.primerApellido || ''} ${
                certificadoSeleccionado.usuario.segundoApellido || ''
              }`,
              tipoGenerado: TipoCertificadoGenerado.EXTERNO,
              urlArchivo: certificadoSeleccionado.linkExterno,
              adjunto: certificadoSeleccionado.adjunto,
              cantidadCaracteres:
                certificadoSeleccionado.datosCertificado.cantidadCaracteres,
              fraseInicial:
                certificadoSeleccionado.datosCertificado.fraseInicial,
              frase: certificadoSeleccionado.datosCertificado.frase,
            }}
            loadingModal={loadingModal}
            isModalOpen={modalEditCertificate}
            handleClose={handleClose}
            handleSave={handleEditCertificate}
          />
        )}
    </MainLayout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: 10,
      maxWidth: '100%',
    },
    tableContainer: {
      maxHeight: 'calc(100vh - 110px)',
      '&::-webkit-scrollbar': {
        width: '3px',
      },
    },
    box: {
      background: '#FFFFFF',
      border: '1px solid #FAE268',
      boxSizing: 'border-box',
      borderRadius: '6px',
      marginTop: '15px',
      display: 'flex',
      margin: theme.spacing(0, 1, 1, 1),
      borderRight: '1px solid #AAAAAA',
    },
    boxItem: {
      padding: '17px',
      background: 'transparent',
      border: 'none',
      borderBottom: '1px solid #AAAAAA',
      borderLeft: '1px solid #AAAAAA',
      textAlign: 'center',
      cursor: 'pointer',
    },
    boxItemSelected: {
      fontWeight: 'bold',
      backgroundColor: '#fff7b080',
    },
    boxItemEnd: {
      padding: '17px',
      background: 'transparent',
      border: 'none',
      width: '100%',
      textAlign: 'left',
    },
    boxTitle: {
      fontSize: '1rem',
      margin: '10px 0',
    },
    filterBox: {
      display: 'flex',
      justifyContent: 'left',
      height: 0,
      alignItems: 'center',
      overflow: 'hidden',
      transition: 'height .5s, marginTop .5s',
      background: '#eee',
      paddingLeft: '16px',
    },
    filterBoxOpen: {
      overflowX: 'auto',
      height: '64px',
    },
    adjuntos: {
      listStyle: 'none',
      paddingLeft: '10px',
    },
    filterItem: {
      minWidth: '190px',
      margin: '0 16px',
    },
    link: {
      color: '#323232',
      display: 'inline-block',
      padding: '0 10px 0',
      textDecoration: 'underline',
    },
  }),
);

export default Certificates;
