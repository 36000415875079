import {
  ICertificate,
  ICertificateEdit,
  ICertificateForm,
  ICertificateGenerate,
  ICertificateUploadForm,
} from 'models/certificate_model';
import http from './http';
import { TipoCertificadoGenerado } from 'views/Certificates';
import { base64ToFile } from './ticket_repository';
import moment from 'moment';

export const getCertificatesService = async (filtro?: {
  tipoGeneracion: TipoCertificadoGenerado;
  nombreUsuario?: string;
  gestion?: string;
  tipoCertificado?: string;
  _limit?: number;
  _page?: number;
}) => {
  try {
    let filters = [];
    if (filtro?.tipoGeneracion) {
      filters.push(`tipoCertificadoGenerado=${filtro.tipoGeneracion}`);
    }
    if (filtro?.nombreUsuario) {
      filters.push(`nombreUsuario=${filtro.nombreUsuario}`);
    }
    if (filtro?.gestion) {
      filters.push(`gestion=${filtro.gestion}`);
    }
    if (filtro?.tipoCertificado) {
      filters.push(`tipoCertificado=${filtro.tipoCertificado}`);
    }
    if (filtro?._limit) {
      filters.push(`_limit=${filtro._limit}`);
    }
    if (filtro?._page) {
      filters.push(`_page=${filtro._page}`);
    }
    const items = await http.get(
      `/certificados/${filters.length ? '?' + filters.join('&') : ''}`,
    );
    return items as { rows: ICertificate[]; count: number };
  } catch (error) {
    console.error('[get_certificates]  Error -> ', error);
    return { rows: [], count: 0 };
  }
};

export const getPlantillasService = async (filtro?: { nombre?: string }) => {
  try {
    let filters = [];
    if (filtro?.nombre) {
      filters.push(`nombre=${filtro.nombre}`);
    }
    const response = await http.get(
      `/certificado/plantillas${filters.length ? '?' + filters.join('&') : ''}`,
    );
    return response as {
      rows: { id: string; nombre: string }[];
      count: number;
    };
  } catch (error) {
    console.error(
      'Ocurrió un error al obtener las plantillas de certificados :: ',
      error,
    );
    return { rows: [], count: 0 };
  }
};

export const createCertificateService = async (data: ICertificateForm) => {
  try {
    const formData = new FormData();
    formData.append('tipoCertificado', data.tipoCertificado);
    formData.append('frase', data.frase);
    formData.append('fechaCertificado', data.fechaCertificado);

    if (data.archivo.length > 0) {
      formData.append('file', data.archivo[0]);
    }
    const response = await http.post(`/certificado`, formData, {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    });

    return response;
  } catch (error) {
    console.error(
      'Ocurrió un error al verificar usuarios del certificado :: ',
      error,
    );
    return null;
  }
};

export const editCertificateService = async (data: ICertificateEdit) => {
  try {
    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('tipoCertificado', data.tipoCertificado || '');
    formData.append('tipoGenerado', data.tipoGenerado);
    formData.append('frase', data.frase || '');
    formData.append(
      'fechaCertificado',
      moment(data.fechaCertificado).format('YYYY-MM-DD'),
    );

    formData.append('urlArchivo', data.urlArchivo || '');

    if (data.adjunto && JSON.parse(data.adjunto)?.length > 0) {
      const file = JSON.parse(data.adjunto)[0];
      const fileObject = base64ToFile(
        file.fileObject.data,
        file.fileName,
        file.fileType,
      );
      formData.append('file', fileObject);
    }
    const response = await http.put(`/certificado/${data.id}`, formData, {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    });

    return response;
  } catch (error) {
    console.error('Ocurrió un error al editar el certificado :: ', error);
    return null;
  }
};

export const deleteCertificateService = async (id: string) => {
  try {
    const response = await http.delete(`/certificado/${id}`);
    return response;
  } catch (error) {
    console.error('Ocurrió un error al eliminar el certificado :: ', error);
    return null;
  }
};

export const uploadCertificateService = async (
  data: ICertificateUploadForm,
) => {
  try {
    const formData = new FormData();
    formData.append('esProfesor', data.esProfesor ? 'true' : 'false');
    formData.append('urlArchivo', data.urlArchivo || '');
    formData.append('usuario', data.usuario || '');

    if (JSON.parse(data.adjunto)?.length > 0) {
      const file = JSON.parse(data.adjunto)[0];
      const fileObject = base64ToFile(
        file.fileObject.data,
        file.fileName,
        file.fileType,
      );
      formData.append('file', fileObject);
    }
    const response = await http.post(`/certificado-upload`, formData, {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    });

    return response;
  } catch (error) {
    console.error(
      'Ocurrió un error al subir el certificado existente :: ',
      error,
    );
    return null;
  }
};

export const downloadCertificateService = async (data: {
  id: string;
  nombreFile: string;
}) => {
  try {
    return http.download(`/certificado/${data.id}`, `${data.nombreFile}`);
  } catch (error) {
    console.error('Ocurrió un error al descargar el certificado :: ', error);
  }
};

export const generarCertificadoTemporalService = async (
  data: ICertificateGenerate,
  fileName: string,
) => {
  try {
    const consulta = `?usuario=${data.idUsuario}&plantilla=${
      data.idPlantilla
    }&fecha=${moment(data.fecha).format('YYYY-MM-DD')}&frase=${data.frase}`;
    return http.download(`/certificado/temporal${consulta}`, `${fileName}.pdf`);
  } catch (error) {
    console.log('Ocurrió un error al generar el certificado ::', error);
    return null;
  }
};

export const generarCertificadoTemporalExistenteService = async (
  data: {
    idCertificado: string;
    frase: string;
    fecha: string;
  },
  fileName: string,
) => {
  try {
    const consulta = `?id=${data.idCertificado}&fecha=${moment(
      data.fecha,
    ).format('YYYY-MM-DD')}&frase=${data.frase}`;
    return http.download(
      `/certificado/temporal/existente${consulta}`,
      fileName,
    );
  } catch (error) {
    console.log('Ocurrió un error al generar el certificado ::', error);
    return null;
  }
};

export const descargarCSVEjemploPeticion = async () => {
  try {
    return await http.download(
      `/certificado/ejemplo`,
      `TEMPLATE_CERTIFICADOS.xlsx`,
    );
  } catch (error) {
    console.error('Ocurrió un error al descargar el ejemplo CSV :: ', error);
  }
};

export const verificarUsuariosCertificadoService = async (
  data: ICertificateForm,
) => {
  try {
    const formData = new FormData();

    formData.append('tipoCertificado', data.tipoCertificado);
    formData.append('frase', data.frase);
    formData.append(
      'fechaCertificado',
      moment(data.fechaCertificado).format('YYYY-MM-DD'),
    );

    if (data.archivo.length > 0) {
      formData.append('file', data.archivo[0]);
    }
    const response = await http.post(
      `/certificado/verificar-usuarios`,
      formData,
      {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    );

    return response as { datos: { nombreCompleto: string }[] };
  } catch (error) {
    console.error(
      'Ocurrió un error al verificar usuarios del certificado :: ',
      error,
    );
    return { datos: [] };
  }
};

export const obtenerUsuariosParaCertificadoService = async (
  tipoUsuario: 'ESTUDIANTE' | 'PROFESOR',
) => {
  try {
    const response = await http.get(
      `/usuarios/certificado?tipoUsuario=${tipoUsuario}`,
    );
    return response as {
      id: string;
      nombre: string;
      codigoCurso: string | null;
      nivel: string | null;
    }[];
  } catch (error) {
    console.error(
      'Ocurrió un error al obtener los usuarios para el certificado :: ',
      error,
    );
    return [];
  }
};
