import { PROFESOR_TYPES, Action } from "../types";
import { IProfesor } from "../../models/profesor_model";

export type State = {
  // profesors: Array<IProfesor> | [];
  profesors: IProfesor[] | [];
};

const initialState: State = {
  profesors: [],
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case PROFESOR_TYPES.LOAD_PROFESORS:
      return {
        ...state,
        profesors: payload,
      };
    default:
      return state;
  }
};

export default reducer;
