import { CIRCULAR_TYPES, Action } from '../types';
import { ICircular } from '../../models/circular_model';

export type State = {
  circulares: ICircular[] | [];
};

const initialState: State = {
  circulares: [],
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case CIRCULAR_TYPES.LOAD_CIRCULARES:
      return {
        ...state,
        circulares: payload,
      };
    default:
      return state;
  }
};

export default reducer;
