import { ESTUDIANTE_TYPES, Action } from "../types";
import { Estudiante } from "../../models/estudiante_model";

export type State = {
  estudiantes: Estudiante[] | [];
  estudiante: Estudiante | null;
  formEstudiante: any;
};

const initialState: State = {
  estudiantes: [],
  estudiante: null,
  formEstudiante: {},
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case ESTUDIANTE_TYPES.CARGAR_ESTUDIANTES:
      return {
        ...state,
        estudiantes: payload,
      };
    case ESTUDIANTE_TYPES.SET_ESTUDIANTE:
      return {
        ...state,
        estudiante: payload
      }
    case ESTUDIANTE_TYPES.SET_FORM_ESTUDIANTE:
      return {
        ...state,
        formEstudiante: payload,
      }
    default:
      return state;
  }
};

export default reducer;
