import React, { useEffect } from 'react';
import MainLayout from '../../components/layout/MainLayout';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ICircular, ICircularForm } from 'models/circular_model';
import { RootState, useStateSelector } from 'store/reducers';
import {
  addCircular,
  cargarCirculares,
  deleteCircular,
  editCircular,
} from 'store/actions/circular';
import message from '../../plugins/utils/message';
import { FilterList, Refresh, Search } from '@material-ui/icons';
import FormCircular from './components/FormCircular';
import DOMPurify from 'dompurify';
import { I18n, Translate } from 'react-redux-i18n';
import Add from '@material-ui/icons/Add';

interface Column {
  id:
    | 'actions'
    | 'codigo'
    | 'asunto'
    | 'contenido'
    | 'fechaCreacion'
    | 'fechaModificacion';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    maxWidth: 450,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.divider}`,
  },
}))(Tooltip);

const Circulares: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [circular, setCircular] = React.useState<ICircularForm | null>(null);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { circulares } = useStateSelector(
    (state: RootState) => state.circularState,
  );

  const columns: Column[] = [
    {
      id: 'actions',
      label: I18n.t('tabla.acciones'),
    },
    {
      id: 'codigo',
      label: I18n.t('form.nroCircular'),
    },
    {
      id: 'asunto',
      label: I18n.t('form.asunto'),
    },
    {
      id: 'fechaCreacion',
      label: I18n.t('tabla.fechaPublicacion'),
    },
    {
      id: 'fechaModificacion',
      label: I18n.t('tabla.fechaActualizacion'),
    },
  ];

  useEffect(() => {
    dispatch(cargarCirculares());
  }, [dispatch]);

  const handleRefreshCirculares = () => dispatch(cargarCirculares());

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const toHome = () => {
    history.push('/');
  };

  const [modalFormCircular, setModalFormCircular] = React.useState(false);

  const handleClickOpen = () => {
    setModalFormCircular(true);
  };

  const handleClose = () => {
    setModalFormCircular(false);
    setCircular(null);
  };

  const handleEditCircular = (circular: ICircular) => {
    setCircular(circular);
    handleClickOpen();
  };

  const handleDeleteCircular = async (id: string) => {
    const deleted = await message.confirm('¿Desea eliminar la circular?');

    if (deleted) {
      dispatch(deleteCircular(id));

      setTimeout(() => {
        handleRefreshCirculares();
      }, 1000);
    }
  };

  // Filters
  const [filter, setFilter] = React.useState(false);
  const [codigoFilter, setCodigoFilter] = React.useState('');
  const [asuntoFilter, setAsuntoFilter] = React.useState('');

  const handleFilter = () => {
    setFilter(!filter);
  };

  const handleCodigoFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodigoFilter(event.target.value);
  };

  const handleAsuntoFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAsuntoFilter(event.target.value);
  };

  const handleSubmitFilter = () => {
    dispatch(cargarCirculares(codigoFilter, asuntoFilter));
    setPage(0);
  };

  const handleFilterCancel = () => {
    setFilter(false);
    setCodigoFilter('');
    setAsuntoFilter('');
    handleRefreshCirculares();
  };

  const handleSaveCircular = (data: ICircularForm) => {
    if (circular?.id) {
      dispatch(editCircular({ ...data }, circular.id));
    } else {
      dispatch(addCircular(data));
    }

    const mensajeGuardandoCircular = circular?.id
      ? I18n.t('form.editandoCircular')
      : I18n.t('form.creandoCircular');
    message.success(mensajeGuardandoCircular);

    setTimeout(() => {
      dispatch(cargarCirculares());
    }, 2000);

    handleClose();
  };

  return (
    <MainLayout>
      <Box mb={2} display="flex" alignItems="center">
        <IconButton onClick={toHome}>
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.85853 11.4101C9.09252 11.2839 9.2384 11.0438 9.2384 10.7827V6.71729H17.2657C17.671 6.71729 18 6.39596 18 6.00003C18 5.6041 17.671 5.28276 17.2657 5.28276H9.2384V1.21731C9.2384 0.955274 9.09252 0.71523 8.85853 0.589948C8.62453 0.462753 8.33865 0.471361 8.11248 0.610031L0.342671 5.39274C0.129236 5.52472 0 5.75329 0 6.00003C0 6.24676 0.129236 6.47533 0.342671 6.60731L8.11248 11.39C8.23193 11.4627 8.36802 11.5 8.50411 11.5C8.62551 11.5 8.74789 11.4694 8.85853 11.4101Z"
              fill={theme.palette.error.main}
            />
          </svg>
        </IconButton>
        <Typography variant="h6">
          <Translate value="modulo.circulares" />
        </Typography>
      </Box>
      <Paper className={classes.root}>
        <Box p={3} mb={2} display="flex">
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            startIcon={<Add />}
          >
            <Translate value="form.agregarCircular" />
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: 10 }}
            onClick={handleFilter}
          >
            <FilterList />
          </Button>
          <Button
            variant="contained"
            onClick={handleRefreshCirculares}
            style={{ marginLeft: 10 }}
            startIcon={<Refresh />}
          >
            {isDesktop && <Translate value="tabla.refrescar" />}
          </Button>
        </Box>
        {filter && (
          <div className={classes.containerFilter}>
            <Translate value="tabla.buscarPor" />
            <br />
            <TextField
              label={<Translate value="form.nroCircular" />}
              value={codigoFilter}
              variant="filled"
              size="small"
              onChange={handleCodigoFilter}
            />
            <TextField
              label={<Translate value="form.asunto" />}
              value={asuntoFilter}
              variant="filled"
              size="small"
              onChange={handleAsuntoFilter}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitFilter}
            >
              <Search />
              <Translate value="tabla.buscar" />
            </Button>
            {(asuntoFilter.length > 0 || codigoFilter.length > 0) && (
              <Button variant="contained" onClick={handleFilterCancel}>
                Cancelar
              </Button>
            )}
          </div>
        )}
        {(circulares.length === 0 ||
          circulares === undefined ||
          circulares === null) && (
          <Typography variant="subtitle1" align="center">
            No hay datos de circulares
          </Typography>
        )}
        {circulares.length > 0 && (
          <>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: 'bold',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {circulares
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: ICircular) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          <TableCell>
                            <Box display="flex">
                              <Tooltip title="Editar circular">
                                <IconButton
                                  size="small"
                                  onClick={() => handleEditCircular(row)}
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M19.9898 18.9533C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533H19.9898ZM16.0299 3.69906L17.5049 4.87078C18.1097 5.34377 18.513 5.96726 18.6509 6.62299C18.8101 7.3443 18.6403 8.0527 18.1628 8.66544L9.3764 20.0279C8.97316 20.5439 8.37891 20.8341 7.74222 20.8449L4.24039 20.8879C4.04939 20.8879 3.89021 20.7589 3.84777 20.5761L3.0519 17.1255C2.91395 16.4912 3.0519 15.8355 3.45514 15.3303L9.68413 7.26797C9.79025 7.13898 9.98126 7.11855 10.1086 7.21422L12.7297 9.29967C12.8994 9.43942 13.1329 9.51467 13.377 9.48242C13.8969 9.41792 14.2471 8.94493 14.1941 8.43969C14.1622 8.1817 14.0349 7.96671 13.8651 7.80546C13.812 7.76246 11.3183 5.76301 11.3183 5.76301C11.1591 5.63401 11.1273 5.39752 11.2546 5.23735L12.2415 3.95706C13.1541 2.78534 14.7459 2.67784 16.0299 3.69906Z"
                                      fill={theme.palette.warning.main}
                                    />
                                  </svg>
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Eliminar circular">
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    row.id ? handleDeleteCircular(row.id) : null
                                  }
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.9391 8.69713C19.1384 8.69713 19.3193 8.78413 19.4623 8.93113C19.5955 9.08813 19.6626 9.28313 19.6432 9.48913C19.6432 9.55712 19.1102 16.2971 18.8058 19.134C18.6152 20.875 17.4929 21.932 15.8094 21.961C14.5149 21.99 13.2496 22 12.0038 22C10.6811 22 9.38763 21.99 8.13206 21.961C6.50498 21.922 5.38168 20.846 5.20079 19.134C4.88763 16.2871 4.36439 9.55712 4.35467 9.48913C4.34494 9.28313 4.41108 9.08813 4.54529 8.93113C4.67756 8.78413 4.86818 8.69713 5.06852 8.69713H18.9391ZM14.0647 2C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647Z"
                                      fill={theme.palette.error.main}
                                    />
                                  </svg>
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                          <TableCell align="left">{row.codigo}</TableCell>
                          <TableCell align="left">
                            <HtmlTooltip
                              title={
                                <div
                                  className={classes.textHtml}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      typeof row.contenido === 'string'
                                        ? DOMPurify.sanitize(row.contenido)
                                        : '',
                                  }}
                                />
                              }
                            >
                              <div>{row.asunto}</div>
                            </HtmlTooltip>
                          </TableCell>
                          <TableCell align="left">
                            {row.fechaPublicacion}
                          </TableCell>
                          <TableCell align="left">
                            {row.fechaModificacion}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 50, 100, 250]}
              component="div"
              count={circulares.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={<Translate value="tabla.filasPorPagina" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${I18n.t('tabla.de')} ${count !== -1 ? count : `${I18n.t('tabla.masDe')} ${to}`}`}
            />
          </>
        )}
      </Paper>
      {modalFormCircular && (
        <FormCircular
          handleClose={handleClose}
          circular={circular}
          handleSave={handleSaveCircular}
          isModalOpen={modalFormCircular}
        />
      )}
    </MainLayout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    container: {
      //
    },
    containerFilter: {
      padding: '0 15px 15px',
      '& .MuiFormControl-root, & .MuiButtonBase-root': {
        marginRight: 5,
      },
      '& .MuiButtonBase-root': {
        marginTop: 6,
      },
    },
    btnAction: {
      margin: '0 3px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    activeChip: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.getContrastText(theme.palette.success.light),
    },
    inactiveChip: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.getContrastText(theme.palette.error.light),
    },
    textHtml: {
      maxWidth: '100%',
      '& img': {
        maxWidth: '400px !important',
        maxHeight: '400px !important',
        objectFit: 'contain',
      },
      '& p': {
        marginTop: 0,
        padding: 0,
      },
    },
  }),
);

export default Circulares;
