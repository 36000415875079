import {
  ICertificate,
  ICertificateEdit,
  ICertificateForm,
  ICertificateGenerate,
  ICertificateUploadForm,
} from 'models/certificate_model';
import {
  createCertificateService,
  deleteCertificateService,
  descargarCSVEjemploPeticion,
  downloadCertificateService,
  editCertificateService,
  generarCertificadoTemporalExistenteService,
  generarCertificadoTemporalService,
  getCertificatesService,
  getPlantillasService,
  uploadCertificateService,
  verificarUsuariosCertificadoService,
} from 'repositories/certificate_repository';
import { CERTIFICATE_TYPES } from 'store/types';
import { TipoCertificadoGenerado } from 'views/Certificates';

export const cargarPlantillasCertificados = () => {
  return async (dispatch: any) => {
    const { rows } = await getPlantillasService();
    dispatch(setPlantillasCertificados(rows || []));
  };
};

export const cargarCertificados = (
  tipoGeneracion: TipoCertificadoGenerado,
  _limit?: number,
  _page?: number,
  nombreUsuario?: string,
  gestion?: string,
  tipoCertificado?: string,
) => {
  return async (dispatch: any) => {
    const { rows, count } = await getCertificatesService({
      tipoGeneracion: tipoGeneracion,
      gestion: gestion,
      nombreUsuario: nombreUsuario,
      tipoCertificado: tipoCertificado,
      _limit: _limit,
      _page: _page,
    });
    dispatch(setCertificates(rows || []));
    dispatch(setCountCertificates(count));
  };
};

export const verificarCertificadoUsuarios = (data: ICertificateForm) => {
  return async (dispatch: any) => {
    const { datos } = await verificarUsuariosCertificadoService(data);
    if (datos && datos.length > 0) {
      dispatch(setUserVerification(datos || []));
      return true;
    } else {
      return false;
    }
  };
};

export const addCertificates = (data: ICertificateForm) => {
  return async (dispatch: any) => {
    return await createCertificateService(data);
  };
};

export const editCertificate = (data: ICertificateEdit) => {
  return async (dispatch: any) => {
    return await editCertificateService(data);
  };
};

export const deleteCertificate = (id: string) => {
  return async (dispatch: any) => {
    return await deleteCertificateService(id);
  };
};

export const uploadCertificate = (data: ICertificateUploadForm) => {
  return async (dispatch: any) => {
    return await uploadCertificateService(data);
  };
};

export const downloadCertificate = (id: string, nombreFile: string) => {
  return async (dispatch: any) => {
    return await downloadCertificateService({ id, nombreFile });
  };
};

export const generarCertificadoTemporal = (
  datos: ICertificateGenerate,
  fileName: string,
) => {
  return async (dispatch: any) => {
    return await generarCertificadoTemporalService(datos, fileName);
  };
};

export const generarCertificadoTemporalExistente = (
  datos: {
    idCertificado: string;
    frase: string;
    fecha: string;
  },
  fileName: string,
) => {
  return async (dispatch: any) => {
    return await generarCertificadoTemporalExistenteService(datos, fileName);
  };
};

export const descargarCSVEjemplo = () => {
  return async (dispatch: any) => {
    return await descargarCSVEjemploPeticion();
  };
};

export const setCertificates = (datos: ICertificate[]) => ({
  type: CERTIFICATE_TYPES.LOAD_CERTIFICATES,
  payload: [...datos],
});

export const setCountCertificates = (count: number) => ({
  type: CERTIFICATE_TYPES.LOAD_CERTIFICATES_COUNT,
  payload: count,
});

export const setUserVerification = (datos: { nombreCompleto: string }[]) => ({
  type: CERTIFICATE_TYPES.VERIFICATE_USER_CERTIFICATES,
  payload: [...datos],
});

export const setPlantillasCertificados = (
  datos: { id: string; nombre: string }[],
) => ({
  type: CERTIFICATE_TYPES.LOAD_PLANTILLAS_CERTIFICATES,
  payload: [...datos],
});
