import { ITicketForm } from 'views/Ticket/components/form-ticket';
import http from './http';

/**
 * Convierte una cadena base64 en un archivo
 * @param base64String
 * @param fileName
 * @param fileType
 * @returns
 */
export function base64ToFile(
  base64String: string,
  fileName: string,
  fileType: string,
) {
  // Decodifica la cadena base64
  const byteString = atob(base64String.split(',')[1]);

  // Crea un ArrayBuffer para almacenar los datos binarios
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // Crea un Blob a partir de los datos binarios
  const blob = new Blob([ab], { type: fileType });

  // Crea un archivo a partir del Blob
  return new File([blob], fileName, { type: fileType });
}

export const createTicketService = async (data: ITicketForm) => {
  const formData = new FormData();

  formData.append('subject', data.asunto);
  formData.append('description', data.descripcion);
  formData.append('location', data.location);

  // Asegúrate de que 'files' esté en formato JSON y conviértelo a un array
  let files = [];
  try {
    files = JSON.parse(data.files || '[]');
  } catch (error) {
    console.error('Error al parsear los archivos:', error);
  }

  // agregar los archivos al formData
  if (files && files.length > 0) {
    files.forEach((file: any, index: number) => {
      const fileObject = base64ToFile(
        file.fileObject.data,
        file.fileName,
        file.fileType,
      );
      formData.append(`files-${index}`, fileObject);
    });
  }

  try {
    return await http.post(`/ticket/`, formData, {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    });
  } catch (error) {
    console.error('Ocurrió un error al enviar el ticket:', error);
    return null;
  }
};

export const getTicketProfesorService = async () => {
  try {
    const items = await http.get(`/ticket-profesor`);
    return items as { rows: any[]; count: number };
  } catch (error) {
    console.error('Error al obtener los tickets del profesor:', error);
    return { rows: [], count: 0 };
  }
};

export const getTicketMonitorService = async () => {
  try {
    const items = await http.get(`/ticket-monitor`);
    return items as { rows: any[]; count: number };
  } catch (error) {
    console.error('Error al obtener los tickets del monitor:', error);
    return { rows: [], count: 0 };
  }
};
