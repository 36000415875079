import http from "./http";

export const getSession = async (tokenFirebase: string) => {
  try {
    const response = await http.get('/session-info', {
      "Authorization": `Bearer ${tokenFirebase}`
    });

    return response;
  } catch (error) {
    console.error("[get] Error :: ", error);
    return null;
  }
};